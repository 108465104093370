.datepicker {
    display: flex;
    gap: 10px;
    align-items: baseline;
    flex-direction: row;

    .react-date-picker__calendar {
        z-index: 2;
    }

    .react-calendar__tile--now {
        background: transparent;
        color: $active-color;
        font-weight: bold;
    }

    .react-calendar__tile--active {
        background: $active-color;
        color: #fff !important;
    }

    .react-date-picker__wrapper {
        // min-width: 168px;
        height: calc(2.25rem + 2px);
        line-height: 1.5;
        padding: 0.375rem 0.5rem;
        border: 1px solid #ced4da;
    }

    .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
    .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
        stroke: $accent-color;
    }

    .react-date-picker__button,
    .react-date-picker__button__icon {
        stroke: #adadad;
    }
}

.search-grid-filter {
    .form-docs {
        width: 218px;
        margin-bottom: 0px;
    }
}


.news-filter-grid {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: row;
    align-items: baseline;
    margin-top: 30px;
}

.datepicker-grid {
    display: flex;
    gap: 20px;
}

@media (min-width: 280px) and (max-width: 480px) {
    .datepicker-grid {
        display: grid;
        grid-template-columns: 100%;
        width: 100%;
        gap: 20px;
        justify-content: space-between;
    }

    .datepicker {
        .react-date-picker {
            width: 100%;
        }
    }

    .search-grid-filter {
        .form-docs {
            width: 100% !important;
        }
    }



    .news-filter-grid {
        flex-direction: column !important;
    }
}

@media (min-width: 480px) and (max-width: 767px) {

    .news-filter-grid {
        flex-direction: column !important;
    }

    .datepicker-grid {
        display: grid;
        grid-template-columns: 48% 48%;
        width: 100%;
        gap: 20px;
        justify-content: space-between;
    }

    .datepicker {
        .react-date-picker {
            width: 100%;
        }
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .news-filter-grid {
        flex-wrap: wrap;
    }

    .react-date-picker {
        width: 217px;
    }

    .category-mobile {
        width: 100%;
    }

    .search-filter {
        width: 440px !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .category-select {
        width: 100% !important;
    }
}