@media (min-width: 768px) and (max-width: 991px) {
    .e-service{
        .block-slideshow__slide-content{
            padding-top: 0px !important;
        }
        .news-ballina{
            margin: 0 6% !important;
            margin-top: 60px !important;
        }
    }
    .container{
        max-width: 960px !important;
    }
    .footer-row {
        flex-wrap: wrap !important;
        flex-direction: column !important;
    }
    .titulli-slider {
        bottom: 75px !important;
    }

    .box-aboutus .slider-boxes{
        grid-template-columns: 49% 49% !important;
        margin: 0 0% !important;
    }
    .aboutus-bordi{
        margin-bottom: 80px !important;
    }
    .box-aboutus .slider-box-link{
        background-color: #1A253C !important;
        padding: 50px 15px 40px !important;
        display: flex;
        flex-direction: column !important;
        gap: 50px;
    }
    .box-aboutus .slider-boxes li :hover {
        background-color: #0c89b5 !important;
    }
    .block {
        margin-bottom: 0px !important;
    }

    .post-card {
        height: 100%!important;
        max-height: 510px  !important;
    }

    .block-posts__news-primary {
        height: 525px !important;
    }

    .news-home{ 
        grid-template-columns: auto !important; 
        grid-gap: 0px !important;
        gap: 0px !important;

    }

    .post-card__image{
        max-height: 360px !important;
    }
        
    .block-posts--layout--grid-nl .post-card .post-card__image {
        height: 100% !important;
    }

    .block-posts .block-news {
        min-height: 325px !important;
    }

    .block-posts__news {
        grid-template-columns: 47.8% 47.8% !important;
    }

    .post-card_second .post-card__image {
        max-height: 180px !important;
            height: 100% !important; 
    }
    
    .post-card_second {
        height: 100% !important;
        max-height: 300px !important;
    }

    .slider-boxes-photo{
        grid-template-columns: 22% 22% 22% !important;
        grid-gap: 80px !important;
        gap: 80px !important;
        justify-content: center; 
    }

    .docsBallin{
        margin-bottom: 30px !important;
    }

    .news-filter-grid {
        margin-bottom: 10px;
    }

    .captcha {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;

    }

    .block-slideshow {
        .slick-dots {
            visibility: visible !important;
            bottom: 10px !important;
        }
    }
    .slider-home {
        .block-header {
            display: none !important;
        }
    }

    .slider-section {
        .box-slider {
            margin-top: 30px !important;
        }
    }

    .contact-box-grid {
        grid-template-columns: auto;
    }

    .news-list {
        .post-card {
            height: 100% !important;
            max-height: 197px !important;
        }
    }

    .contact-us__container {
        margin: 0px 4%;
    }

    .category-mobile {
        display: block;
        margin-bottom: 20px;
        // margin: 15px 0px !important;
        width: 260px !important;
    }

    .category-dekstop {
        display: none;
    }

    .block-event__date {
        width: 60px !important;
        height: 60px !important;
    }

    .event-date-day {
        bottom: 15px !important;
        font-size: 32px !important;
    }

    .event-text {
        height: 100%;
    }

    .posts-view__pagination {
        padding-top: 40px !important;
        padding-bottom: 0 !important;
    }

    .event-month {
        margin-top: 4px !important;
        font-size: 18px !important;
    }

    .search-docs-grid {
        padding-bottom: 10px;
    }

    .box-grid {
        grid-template-columns: 50% 50% !important;
    }

    .box-link {
        margin-bottom: $block-margin-bottom-md;
    }

    .full-side-box-docs {
        min-height: 80px;
    }

    .last-news-left {
        border-right: none !important;
    }

    .galery-row {

        .videogalery-box,
        .fotogalery-box {

            .block-galery-home--default,
            .block-galery-home--thesari,
            .block-galery-home--buxheti,
            .block-galery-home--tatimiNeProne,
            .block-galery-home--raportimet,
            .block-galery-home--socialet,
            .block-galery-home--puna,
            .block-galery-home--punesimet,
            .block-galery-home--partneriteti,
            .block-galery-home--pensionet {
                .block-galery-box {
                    .galery-home {
                        .galery-home-card {
                            .galery-home-box--default,
                            .galery-home-box--thesari,
                            .galery-home-box--buxheti,
                            .galery-home-box--tatimiNeProne,
                            .galery-home-box--raportimet,
                            .galery-home-box--socialet,
                            .galery-home-box--puna,
                            .galery-home-box--punesimet,
                            .galery-home-box--partneriteti,
                            .galery-home-box--pensionet {

                                img,
                                video,
                                iframe {
                                    height: 180px !important;
                                }

                            }
                        }
                    }
                }
            }

        }
    }

    .small-side-box {
        min-height: 80px;
    }

    .galery-row-full {

        .videogalery-box,
        .fotogalery-box,
        .ballinaBoxes-grid {

            .block-galery-home--default,
            .block-galery-home--thesari,
            .block-galery-home--buxheti,
            .block-galery-home--tatimiNeProne,
            .block-galery-home--raportimet,
            .block-galery-home--socialet,
            .block-galery-home--puna,
            .block-galery-home--punesimet,
            .block-galery-home--partneriteti,
            .block-galery-home--pensionet {
                max-width: 100% !important;

                .block-galery-box {
                    .galery-home {
                        .galery-home-card {
                            .galery-home-box--default,
                            .galery-home-box--thesari,
                            .galery-home-box--buxheti,
                            .galery-home-box--tatimiNeProne,
                            .galery-home-box--raportimet,
                            .galery-home-box--socialet,
                            .galery-home-box--puna,
                            .galery-home-box--punesimet,
                            .galery-home-box--partneriteti,
                            .galery-home-box--pensionet {

                                img,
                                video,
                                iframe {
                                    height: 380px !important;
                                    max-width: 100% !important;
                                }

                            }
                        }
                    }
                }
            }

        }
    }

    .galery-box {
        .ballinaBoxes-grid {
            .block-galery-home--default,
            .block-galery-home--thesari,
            .block-galery-home--buxheti,
            .block-galery-home--tatimiNeProne,
            .block-galery-home--raportimet,
            .block-galery-home--socialet,
            .block-galery-home--puna,
            .block-galery-home--punesimet,
            .block-galery-home--partneriteti,
            .block-galery-home--pensionet {
                .block-galery-box {
                    .galery-home {
                        .galery-home-card {
                            .galery-home-box--default,
                            .galery-home-box--thesari,
                            .galery-home-box--buxheti,
                            .galery-home-box--tatimiNeProne,
                            .galery-home-box--raportimet,
                            .galery-home-box--socialet,
                            .galery-home-box--puna,
                            .galery-home-box--punesimet,
                            .galery-home-box--partneriteti,
                            .galery-home-box--pensionet {

                                img,
                                video,
                                iframe {
                                    height: 180px !important;
                                }

                            }
                        }
                    }
                }
            }

        }
    }

    .galery-row-half {
        .ballinaBoxes-grid {
            .block-galery-home--default,
            .block-galery-home--thesari,
            .block-galery-home--buxheti,
            .block-galery-home--tatimiNeProne,
            .block-galery-home--raportimet,
            .block-galery-home--socialet,
            .block-galery-home--puna,
            .block-galery-home--punesimet,
            .block-galery-home--partneriteti,
            .block-galery-home--pensionet {
                .block-galery-box {
                    .galery-home {
                        .galery-home-card {     
                            .galery-home-box--default,
                            .galery-home-box--thesari,
                            .galery-home-box--buxheti,
                            .galery-home-box--tatimiNeProne,
                            .galery-home-box--raportimet,
                            .galery-home-box--socialet,
                            .galery-home-box--puna,
                            .galery-home-box--punesimet,
                            .galery-home-box--partneriteti,
                            .galery-home-box--pensionet {

                                img,
                                video,
                                iframe {
                                    height: 180px !important;
                                }

                            }
                        }
                    }
                }
            }

        }
    }


    .how-do-i-btn {
        width: 100% !important;
    }

    .mobile-header__panel {
        .container {
            max-width: 100%;

            .mobile-header__body {
                margin: 0 0% !important;
            }
        }
    }

    .block-docs-links,
    .galery-row,
    .galery-row-full,
    .ballinaBoxes-fp,
    .ballina-text-section,
    .galery-box {
        margin: 0 4% !important;
    }

    .full-news-section {
        justify-content: start !important;
        width: 100%;
    }

    .atoz-content {
        justify-content: flex-start !important;
    }

    .block-event-box {
        margin: 0 4% !important;
    }

    .link-childs-list {
        flex-direction: row !important;
        gap: 5px
    }

    .block-slideshow__slide-content {
        position: static !important;
        align-items: center !important;
        display: flex;
        padding-top: 30px !important;
        padding-bottom: 0px !important;
        justify-content: center;
    }

    .slider-boxes {
        // background-color: #f8f9fa;
        margin-top: 30px !important;
        padding: 0px 0;
        flex-wrap: wrap;
        display: grid !important;
        margin: 0 6% !important;
        grid-template-columns: auto auto !important;
        grid-gap: 10px !important;
    }

    .slider-boxes li img {
        position: relative !important;
        top: 0px !important;
        margin-left: 30px;
        left: 0% !important;
    }

    .slider-boxes a, .digitarkep {
        display: flex;
        width: 100%;
        padding: 25px 15px 25px;
        border: 1px solid $border-blue-box;
        box-shadow: $block-box-shadow-small;
        border-radius: 3px;
        background-color: #f8f9fa !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: center;
        text-align: left;
    }


    .slider-box-link-childs {
        border: 1px solid #003c8726;
        box-shadow: 0px 2px 5px 0 rgb(0 0 0 / 10%);
        border-radius: 3px;
        background-color: #f8f9fa !important;
        display: flex;
        flex-direction: row !important;
        padding: 25px 15px 25px !important;
        justify-content: flex-start !important;

        a {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            margin: 0 !important;
            padding: 0 !important;
            margin-bottom: 0;
        }

        img {
            // margin-left: 45px !important;
        }
    }

    .search--location--mobile-header .search__button {
        width: auto !important;
        padding: 0 22px !important;
        background: transparent url(../../img/Search-icone/search.png) right 10px center no-repeat !important;
        background-size: 26px 26px !important;
        font-weight: 400;
        font-size: 0;
    }

    .search__icone {
        background: #04408b26;
        display: flex;
        justify-content: center;
    }

    .site-footer__widgets {
        margin: 0px 1vh !important;
    }

    .footer-contact-list {
        margin: 5rem 0vh !important;
    }

    .top-footer-grid {
        width: calc(30% - 1px) !important;
    }

    .footer-grid-text,
    .contacts-text-box {
        width: 100% !important;
    }

    .contacts-text-box {
        align-items: center !important;
        margin-bottom: 30px;
    }


    .content-vegzat-content .widget-categories {
        width: 100% !important;
        margin-bottom: $block-margin-bottom-md;
    }

    .statistics-list {
        display: flex;
        margin-bottom: 6rem;
        flex-wrap: wrap;
    }

    .feature-box-list {
        margin-top: 4rem;
        display: flex;
        margin-bottom: 4rem;
        flex-wrap: wrap;
    }

    .government-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap !important;
    }

    .block-BlockAboutUs {
        margin-top: 150px !important;
        margin-left: 10vh !important;
        margin-right: 10vh !important;
        margin-bottom: 150px !important;
    }

    .about-section {
        justify-content: center !important;
        flex-wrap: wrap !important;
        align-items: center !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .about-content {
        margin-top: $block-margin-bottom-md;
        align-items: center;
    }

    .about-text {
        text-align: center;
        margin-bottom: 10px;
    }

    .post-card__image img {
        height: 180px;
    }

    // .block-header__arrows-list {
    //     display: flex;
    //     margin-top: 0px;
    //     /* width: 94vw; */
    //     justify-content: center;
    //     align-items: center;
    // }

    .block-slideshow--layout--full .block-slideshow__body,
    .block-slideshow--layout--full .block-slideshow__slide {
        height: 400px !important;
    }

    .block-slideshow__slide-content {
        left: 0 !important;
        bottom: 0 !important;
        top: 75%;
    }

    .block-slideshow__slide-text {
        display: none;
    }

    .block-slideshow__slide-image {
        background-position: 50% 20% !important;
    }

    .site-footer__logo {
        margin-bottom: 12px;

        img {
            width: 100%;
        }
    }

    .post-card--layout--list {
        height: 100% !important;
        max-height: 197px !important;
    }

    .page-header {
        margin-top: 20px;
    }

    .avatar-img img {
        height: 100%;
    }

    .personel-info-position>.personel-info-text {
        width: 70% !important;
    }
}


@media (min-width: 768px) and (max-width: 879px) {

}

@media (min-width: 768px) and (max-width:941px) {

    .slider-section .box-slider .slider-boxes .slider-box-link {
        padding-left: 0px !important;
        font-size: 18px !important;
    }

    .slider-row{
        flex-direction: column !important;
    } 

    .block-event__image img {
        max-height: none !important;
        height: 100%;
    }
}
// 880px
@media (min-width: 942px ) and (max-width: 991px) {
   
    .slider-row {
        flex-direction: column !important;
    }

    .slider-section .box-slider .slider-boxes .slider-box-link {
        padding-left: 0px !important;
        font-size: 18px !important;
    }

}
@media (min-width: 851px) and (max-width:920px) {

    .footer-row {
        display: flex;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        gap: 20px !important;
    }

    .footer-img-box {
        width: 30% !important;
        max-width: 30% !important;
    }
}

@media (min-width: 920px) and (max-width:991px) {

    .footer-row {
        display: flex;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        gap: 30px !important;
    }

    .footer-img-box {
        width: 30% !important;
        max-width: 30% !important;
    }
}
@media (min-width: 768px) and (max-width:850px) {

    .footer-links__list {
        grid-gap: 12px !important;
        gap: 12px !important;
    }

    .footer-row {
        display: flex;
        justify-content: center !important;
        flex-direction: column;
        align-items: center;
        grid-gap: 30px !important;
        gap: 50px !important;
    }

    .footer-links {
        text-align: center;
    }

    .footer-contacts {
        text-align: center;
    }

    .footer-contacts__contacts {
        display: flex !important;
        flex-wrap: nowrap !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
}
@media (min-width: 768px) and (max-width: 791px) {
    .footer_pages_order {
        grid-gap: 0px !important; 
        gap: 0px !important; 
    }
    .footer_pages_color a {
        padding-right: 0px !important;
    }
}
@media (min-width: 792px ) and (max-width: 908px) {
    .footer_pages_order {
        grid-gap: 19px !important;
        gap: 19px !important;
    }
    .footer_pages_color a {
        padding-right: 19px !important;
        &:last-child{
            padding-right: 0px !important;
        }
    }
} 
