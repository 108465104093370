/*
// .block-features
*/
@import '../variables';
@import '../functions';
@import '../mixins/card';
@import '../mixins/direction';


.block-features {
}
.block-features__list {
    @include card();
    border: 1px solid rgba(0, 0, 0, 0) !important;
    // box-shadow: 0px 5px 15px 0 rgb(0 0 0 / 10%) !important;
    background-color: $block-features-background;
    border-radius: 2px !important;
    display: flex;
}


.block-features__item {
    flex-basis: 0;
    flex-grow: 1;
    padding: 16px 24px;
    display: flex;
}
.block-features__divider {
    flex-shrink: 0;
}
.block-features__icon {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    fill: $block-features-icon-color;
    display: flex;
    justify-content: center;  
    align-items: flex-end;


    @include direction {
        #{$margin-inline-end}: 0px;
    }

    svg {
        display: block;
    }

    img {
        width: 35px;
        height: 35px;
    }
}


.block-linkbox-img img{
    width: 250px;
    height: 190px;
    object-fit: cover;   
}

.block-linkbox-img-fp img{
    width: 240px;
    height: 150px;
    object-fit: cover;   
}

.block-linkbox-img-fp {
    border: $block-box-border;
    box-shadow: $block-box-shadow;
    margin-right: 15px;
    margin-bottom: 15px;
}

.block-linkbox-img-fp:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: $block-box-shadow-hover;
}

.block-linkbox-img {
    border: $block-box-border;
    box-shadow: $block-box-shadow;
}

.block-linkbox-img:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: $block-box-shadow-hover;
}

.block-features__content {
    padding-top: 2px;
}
.block-features__title {
    font-size: 17px;
    line-height: 30px;
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.block-features__subtitle {
    font-size: 14px;
}

.block-features--layout--full-page {
    $local-margin: 14px;

    margin-top: $block-margin-top;
    margin-bottom: 50px;

    .block-features__list {
        border: none;
    }
    .block-features__item {
        flex-direction: column;
        background: $block-features-boxed-bg;
        border-radius: 3px;
        text-align: center;
        align-items: center;
        padding: 30px 28px 28px;
    }
    .block-features__divider {
        width: $local-margin;
    }
    .block-features__icon {
        margin-bottom: 12px;

        @include direction {
            #{$margin-inline-end}: 0;
        }
    }

    @media (min-width: 480px) and (max-width: breakpoint(md-end)) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            width: 50%;
        }
        .block-features__divider:nth-child(4n) {
            width: 100%;
            height: $local-margin;
        }
    }

    @media (max-width: 479px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            width: 100%;
        }
        .block-features__divider {
            width: 100%;
            height: $local-margin;
        }
    }
}

.block-features--layout--boxed {
    $local-margin: 14px;

    margin-top: 40px;
    margin-bottom: 50px;

    .block-features__list {
        border: none;
    }
    .block-features__item {
        flex-direction: column;
        background: $block-features-boxed-bg;
        border-radius: 3px;
        text-align: center;
        align-items: center;
        padding: 30px 28px 28px;
    }
    .block-features__divider {
        width: $local-margin;
    }
    .block-features__icon {
        margin-bottom: 12px;

        @include direction {
            #{$margin-inline-end}: 0;
        }
    }

    @media (min-width: 480px) and (max-width: breakpoint(md-end)) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            width: 50%;
        }
        .block-features__divider:nth-child(4n) {
            width: 100%;
            height: $local-margin;
        }
    }

    @media (max-width: 479px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            width: 100%;
        }
        .block-features__divider {
            width: 100%;
            height: $local-margin;
        }
    }
}

.block-features--layout--sidebar {
    margin-top: -($block-margin-bottom - 30px);
    margin-bottom: 40px;

    .block-features__divider {
        flex-shrink: 0;
        width: 2px;
        background: $card-border-color;
    }

    .block-features__item {
        align-items: center;
    }

    .block-features__subtitle {
        font-size: 12px;
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)), (max-width: breakpoint(sm-end)) and (min-width: breakpoint(sm-start)){
        .block-features__item {
            padding: 12px;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 16px;
            }
        }
        .block-features__content {
            padding-top: 0;
        }
    }

    @media (max-width: breakpoint(md-end)) and (min-width: 420px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: calc(50% - 1px);
            flex-shrink: 0;
        }
        .block-features__divider:nth-child(4n) {
            width: 100%;
            height: 2px;
        }
    }

    @media (max-width: breakpoint(xs-end)) and (min-width: 420px) {
        .block-features__item {
            text-align: center;
            flex-direction: column;
        }
        .block-features__icon {
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: center;

            @include direction {
                #{$margin-inline-end}: 0;
            }
        }
    }

    @media (max-width: 419px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: 100%;
            flex-shrink: 0;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 24px;
            }
        }
        .block-features__divider {
            width: 100%;
            height: 2px;
        }
    }
}

.block-features--layout--fullpage-satistics {
    margin-top: -($block-margin-bottom - 30px);
    margin-bottom: 10px;

    .block-features__divider {
        flex-shrink: 0;
        width: 2px;
        background: $card-border-color;
    }

    .block-features__item {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .block-features__subtitle {
        font-size: 12px;
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)), (max-width: breakpoint(sm-end)) and (min-width: breakpoint(sm-start)){
        .block-features__item {
            padding: 12px;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 16px;
            }
        }
        .block-features__content {
            padding-top: 0;
        }
    }

    @media (max-width: breakpoint(md-end)) and (min-width: 420px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: calc(50% - 1px);
            flex-shrink: 0;
        }
        .block-features__divider:nth-child(4n) {
            width: 100%;
            height: 2px;
        }
    }

    @media (max-width: breakpoint(xs-end)) and (min-width: 420px) {
        .block-features__item {
            text-align: center;
            flex-direction: column;
        }
        .block-features__icon {
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: center;

            @include direction {
                #{$margin-inline-end}: 0;
            }
        }
    }

    @media (max-width: 419px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: 100%;
            flex-shrink: 0;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 24px;
            }
        }
        .block-features__divider {
            width: 100%;
            height: 2px;
        }
    }
}

.block-features--layout--classic {
    margin-top: -($block-margin-bottom - 30px);
    margin-bottom: 10px;

    .block-features__divider {
        flex-shrink: 0;
        width: 2px;
        background: $card-border-color;
    }

    .block-features__item {
        align-items: center;
    }

    .block-features__subtitle {
        font-size: 12px;
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)), (max-width: breakpoint(sm-end)) and (min-width: breakpoint(sm-start)){
        .block-features__item {
            padding: 12px;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 16px;
            }
        }
        .block-features__content {
            padding-top: 0;
        }
    }

    @media (max-width: breakpoint(md-end)) and (min-width: 420px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: calc(50% - 1px);
            flex-shrink: 0;
        }
        .block-features__divider:nth-child(4n) {
            width: 100%;
            height: 2px;
        }
    }

    @media (max-width: breakpoint(xs-end)) and (min-width: 420px) {
        .block-features__item {
            text-align: center;
            flex-direction: column;
        }
        .block-features__icon {
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: center;

            @include direction {
                #{$margin-inline-end}: 0;
            }
        }
    }

    @media (max-width: 419px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: 100%;
            flex-shrink: 0;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 24px;
            }
        }
        .block-features__divider {
            width: 100%;
            height: 2px;
        }
    }
}


.statistics-fp {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.statistics-list {
    display: flex;
    margin-bottom: 6rem;
    margin-top: 5rem;
}
.text-contact h2{
    font-size: 26px;
}
.text-contact p{
    font-size: 20px;
    margin: 0 !important;
}

.contacts-text-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
}

.statistics-contnet {
    display: flex;
}

.statistic-grid {
    flex-direction: column;
    justify-content: center;
}

.statistic-title {
    margin-top: 2rem;
    font-weight: $font-weight-light;
    font-size: 20px;
}
.statistic-title2 {
    margin-top: 1rem;
    font-weight: $font-weight-light;
    font-size: 20px;
}

.statistic-title-top {
    text-align: center;
}

.statistic-numbers {
    font-size: 40px;
    font-weight: $font-weight-semi-bold;
}

.statistic-content-text {
    line-height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.statistic-text {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 50%;
    text-align: center;
    color: $light-opposite-color-text-light;
    font-weight: $font-weight-thin;
}

.statistic-title-top h2{
    margin-top: 6rem;
    font-weight: $font-weight-normal;
    font-size: 40px;
}

.block-statistic--layout--classic {
    margin-top: 0px;
    margin-bottom: 0px;

    .block-features__divider {
        flex-shrink: 0;
        width: 2px;
        background: $card-border-color;
    }

    .block-features__item {
        align-items: center;
    }
    .top-footer-grid{
        border-right:1px solid #ccc;
        padding: 0px 0px !important;
    }
    .top-footer-grid:first-child{
        border-right:none;
    }
    .top-footer-grid:last-child{
        border-right:none;
    }
    

    .block-features__subtitle {
        font-size: 12px;
    }


   

    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)), (max-width: breakpoint(sm-end)) and (min-width: breakpoint(sm-start)){
        .block-features__item {
            padding: 12px;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 16px;
            }
        }
        .block-features__content {
            padding-top: 0;
        }
    }

    @media (max-width: breakpoint(md-end)) and (min-width: 420px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: calc(50% - 1px);
            flex-shrink: 0;
        }
        .block-features__divider:nth-child(4n) {
            width: 100%;
            height: 2px;
        }
    }

    @media (max-width: breakpoint(xs-end)) and (min-width: 420px) {
        .block-features__item {
            text-align: center;
            flex-direction: column;
        }
        .block-features__icon {
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: center;

            @include direction {
                #{$margin-inline-end}: 0;
            }
        }
    }

    @media (max-width: 419px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: 100%;
            flex-shrink: 0;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 24px;
            }
        }
        .block-features__divider {
            width: 100%;
            height: 2px;
        }
    }
}