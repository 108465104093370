/*
// .site-header
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';


.site-header {
    position: relative;
    z-index: 99;
    background: $header-bg;
    color: $header-font-color;
}
.site-header__middle {
    display: flex;
    border-bottom: $topbar-border-bottom;
    align-items: center;
    height: 45px;
    justify-content: space-between;
    background-color: #1a253c;
}
.topbar-left-box{
    display: flex;
    width: 50%;
    gap: 10px;
    justify-content: flex-end;
    margin: 0 5% 0 0;
    align-items: center;
}
.search-topbar{
    width: 30%;
}

.site-header__bottom {
    display: flex;
    align-items: center;
    background-color:  $nav-panel-bottom-background;
    position: absolute;
    right: 0;
    width: 100%;
    height: 100px;
    justify-content: flex-end;
}

.site-header__bottom_relative {
    display: flex;
    align-items: center;
    background-color:  $nav-panel-bottom-background;
    position: relative;
    right: 0;
    width: 100%;
    height: 100px;
    justify-content: flex-end;
}

.site-nav-bottom {
    margin: 0 5%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.site-nav-topbar {
    margin: 0 5%;
    display: flex;
    align-items: center;
    width: 50%;
}

.topbar-socials {
    .social-links__list {
        margin: 0;
    }
}

.site-header__logo {
    display: none;
}

.site-header__logoscroll {
    display: block;
    margin-bottom: 0px;
    object-fit: cover;
    flex-shrink: 0;
    // margin-left: 20px;
    width: 90px;
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    // justify-content: center;
    color: $header-logo-color;

    @include direction {
        #{$margin-inline-end}: 0px;
    }

    a {
        color: inherit;
    }

    svg {
        fill: currentColor;
    }
    
    img {
        max-height: 50px;
    }
}

.site-header__logo-bottom {
    margin-bottom: 0px;
    object-fit: cover;
    flex-shrink: 0;
    // margin-left: 20px;
    width: auto;
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    // justify-content: center;
    color: $header-logo-color;

    @include direction {
        #{$margin-inline-end}: 0px;
    }

    a {
        color: inherit;
    }

    svg {
        fill: currentColor;
    }
    
    img {
        max-height: 60px;
    }
}
.site-header__search {
    width: 110vh;
}
.site-header__phone {
    line-height: 1;
    flex-shrink: 0;

    @include direction {
        text-align: $inline-end;
        #{$margin-inline-start}: 80px;
    }
}
.site-header__phone-title {
    font-size: 14px;
    color: $header-font-muted-color;
    margin-bottom: 6px;
}
.site-header__phone-number {
    font-size: 20px;
    font-weight: $font-weight-bold;
}
.site-header__nav-panel {
    height: $nav-panel-height;
}


@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .site-header__logoscroll {
        width: 210px;
        justify-content: flex-start;

        @include direction {
            #{$margin-inline-end}: 24px;
        }
    }
}


.how-do-i-btn {
    letter-spacing: 0.5px;
    border: none;
    padding: 8px 20px;
    background: $accent-color;
    color: $light-color;
    font-weight: $font-weight-semi-bold;
    flex-grow: 0;
    width: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: $header-search-input-default-icon-color;
    transition: fill .15s;

    &:hover,
    &:focus {
        outline: none;
        // fill: $header-search-input-default-icon-hover-color;
        background: $hover-accent-color;
        transition: $button-transition;

        .search__icone {
            outline: none;
            // fill: $header-search-input-default-icon-hover-color;
            background: $hover-accent-color;
            transition: $button-transition;
        } 
    }
}

.search-plus {
    margin-left: 50px;
}


.search__icone, .search-plus {
    color: $light-color;
    font-weight: $font-weight-semi-bold;
    fill: $header-search-input-default-icon-color;

    &:hover,
    &:focus {
        outline: none;
        fill: $header-search-input-default-icon-hover-color;
    }
}

.topbar-box-content {
    margin-left: 20px;
}

.nav-links__item-topbar a{
    font-size: 14px;
    color: white;
}

.topbar-item:hover {
    color: $link-hover-color;
}