/*
// .block
*/
@import '../variables';
@import '../functions';


.block {
    margin-bottom: $block-margin-bottom;
    margin-top: $block-margin-top;
}
.contact-block{
    margin-top: 0px !important;
}

.block-news-section {
    margin-top: $block-margin-top !important;
}
.block-news-category{
    margin-top: 30px;
}

.block--highlighted {
    padding: 50px 0 60px;
    background: $block-highlighted-bg;
}

@media (max-width: breakpoint(md-end)) {
    .block {
        margin-bottom: $block-margin-bottom-md;
    }
}