@import '../../variables';

.tatimi-panel{
    background:  $accent-color !important;
}

.tatimi-panel > .nav-panel__container > .nav-panel__row > .nav-links > .nav-links__list > .nav-links__item > a > .nav-title {
    color: $light-color;
}

.tatimi-panel > .nav-panel__container > .nav-panel__row > .nav-links > .nav-links__list > .nav-links__item::after {
    display: block;
    content: "";
    border-bottom: solid 4px transparent;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.tatimi-panel > .nav-panel__container > .nav-panel__row > .nav-links > .nav-links__list > .nav-links__item:hover:after {
    border-color: $accent-color;
    transform: scaleX(1);
}
