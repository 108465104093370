.frequency-block {
    margin: 0 8%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    margin-bottom: 60px;
}

.frequency-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.frequency-table {
    border: 1px solid rgba(0, 0, 0, 0.025);
    box-shadow: 0px 5px 15px 0 rgb(0 0 0 / 10%);
    border-radius: 2px;
    // padding: $widget-blog-padding;
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 1fr;
    padding: 30px;
    gap: 30px;

    .frequency-search {
        grid-column: 1/-1;

        button {
            width: 100%;
            color: $light-color;
            background-color: $accent-color;
            padding: 5px;
            border: none;
        }
    }
}

.frequency-range {
    display: flex;
    gap: 10px;
    flex-direction: column;

    h5 {

    }
}

.range-inputs {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 15px;

    .frequency-input {
        display: flex;
        gap: 10px;
        align-items: baseline;

        input {
            width: 100% !important;
        }
    }
}

.frequency-Data {
    width: 100%;

    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows {
        margin-bottom: 0 !important;
    }

    .MuiPaper-elevation {
        border: 1px solid #eaeaea;
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 3px 0px rgba(0,0,0,0.12);
    }

    .MuiTableCell-head {
        font-weight: 600;
        font-family: "Montserrat" !important;
    }

    .MuiTableCell-body {
        font-family: "Montserrat" !important;
        // font-weight: 500;
    }
}

.frequency-page-text {
    margin-top: 30px;
    margin-bottom: -30px;
}

.frequency-filter {
    display: flex;
    gap: 10px;
    align-items: flex-end;

    .mantine-Input-input {
        margin-bottom: 10px;
        height: calc(2.25rem + 2px);
    }

    .mantine-Select-label {
        font-weight: 600;
        font-family: "Montserrat" !important;
    }
    .mantine-Select-root {
        width: 50%;
    }
}

@media (min-width: 1200px) and (max-width: 1620px)  {
    .frequency-table {
        width: 100%;
    }
}

@media (max-width: breakpoint(lg-end)) {
    .frequency-section {
        width: 100%; 
    }

    .frequency-table {
        width: 100%;
        grid-template-columns: 1fr;
        gap: 10px;
    }
    .range-inputs {
        grid-template-columns: 2fr 2fr 1fr;
    }

    .frequency-filter {
        margin-bottom: 10px;
        flex-direction: flex;
        .mantine-Select-root {
            width: 50%;
        }
    }
}

@media (max-width: breakpoint(sm-end)) {
    .frequency-section {
        width: 100%; 
    }

    .frequency-table {
        width: 100%;
        grid-template-columns: 1fr;
        gap: 10px;
    }
    .range-inputs {
        grid-template-columns: 1fr;
    }

    .frequency-filter {
        margin-bottom: 10px;
        flex-direction: column;
        .mantine-Select-root {
            width: 100%;
        }
    }
}