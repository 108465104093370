@import '../variables';

.block-apliko {
    margin-top: 0px;
    margin-bottom: 0px;
}

// .Apliko-section{
//     width: 99.1%;
// }
.Apliko-section{
    width: 99.1%;
}

@media (min-width: 500px) and (max-width: 1647px) {
    .Apliko-section{
        width: 100%;
    }
}

.block-apliko {
    background-color: $accent-color;
}

.apliko-content {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.apliko-text {
    font-size: 24px;
    color: $light-color;
    margin-right: 30px;
}

.btn-apliko {
    background-color: $light-color;
    color: $light-opposite-color;
    text-transform: uppercase;
    font-size: 16px;
}