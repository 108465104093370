@import '../variables';



@media (min-width: 280px) and (max-width: 767px) {
    .slider-section {
        .box-slider {
            margin-top: 30px !important;
        }
    }

    .slick-dots {
        z-index: 2;
    }

    .e-service{
        .slider-boxes{
            margin-top: 0px !important;
        }
    }
    .footer-links__list {
        grid-gap: 12px !important;
        gap: 12px !important;
    }

    .footer_pages_color a {
        padding-right: 0px !important;
        border-right: 0px !important;
    }
    .footer-row {
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center;
        flex-wrap: wrap !important;
    }
    .box-aboutus {
        .slider-boxes {
            display: grid;
            grid-template-columns: auto !important;
            margin: 0;
        }
    }

    .personel-details-data-aboutus {
        display: flex;
        flex-direction: column !important;
        justify-content: space-around;
        gap: 30px;
    }

    .box-aboutus .slider-box-link {
        background-color: #1A253C !important;
    }

    .box-aboutus .slider-box-link {
        display: flex;
        flex-direction: column !important;
        gap: 50px;
        padding: 50px 15px 40px !important;
    }

    .box-aboutus .slider-boxes li :hover {
        background-color: #0c89b5 !important;
    }

    .block-posts__news-primary {
        height: auto !important;
    }

    .block-posts__news {
        grid-template-columns: auto !important;
    }

    .news-home {
        grid-template-columns: auto !important;
        grid-gap: 0px !important;
        gap: 0px !important;

    }

    .slider-boxes-photo {
        grid-template-columns: auto !important;
    }

    .event-first-title {

        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 0px !important;

        a {
            font-size: 18px !important;
        }

        h2 {
            font-size: 18px !important;
        }
    }

    .mobile-showAll {
        display: flex !important;
        padding: 5px 0px;
    }

    .mobile-hide {
        display: none !important;
    }

    .docsBallin {
        margin-bottom: 30px;
        gap: 10px !important;
    }

    .file-name {
        font-size: 16px !important;
    }

    .footer_pages_order {
        font-size: 16px !important;
        gap: 18px !important;
        margin-top: 0px !important;
        margin-bottom: 24px !important;
        padding-left: 6px !important;
        flex-direction: column !important;
    }

    .footer_pages_order span {
        border: 1px solid white !important;
        // height: 100%;
        width: 12% !important;
    }

    .site-footer__copyright,
    .site-footer__payments {
        width: 100%;
        padding: 24px 0 !important;
        text-align: center;
    }

    .news-filter-grid {
        padding: 0 15px;
        margin-bottom: 35px !important;
    }

    .category-mobile {
        margin: 0 15px;
        margin-bottom: 20px;
    }

    .post-header__image {
        .lazy-load-image-background {
            min-height: 160px !important;
        }
    }

    .lazy-load-image-background.blur.lazy-load-image-loaded>img,
    .lazy-load-image-background.blur>img {
        min-height: auto !important;
    }

    .social-links__list {
        margin: 0px !important;
    }

    .footer-links {
        margin: 0 !important;
    }

    .site-footer__widgets {
        .row {
            gap: 50px;
        }
    }

    .captcha {
        width: 50px;

        div {
            // width: 100% !important;
            height: auto !important;
        }

        iframe {
            transform: scale(0.77);
            transform-origin: 0 0;
            // width: 100% !important;
        }
    }


    .contact-body {
        padding: 50px 25px;
    }

    .contact-box {
        padding: 20px;
    }

    .g-recaptcha {
        transform: scale(0.77);
        transform-origin: 0 0;
    }

    .captcha {
        transform: scale(0.77);
        -webkit-transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;

    }

    .rc-anchor-normal {
        width: auto !important;
    }

    .and-devider {
        justify-content: start !important;
    }


    .block-slideshow {
        .slick-dots {
            visibility: visible !important;
        }
    }

    .slider-home {
        .block-header {
            display: none !important;
        }
    }

    //docs home 
    .block-docs-links {
        margin: 0 !important;
    }

    //galery ballin

    .galery-home-box--default,
    .galery-home-box--thesari,
    .galery-home-box--buxheti,
    .galery-home-box--socialet,
    .galery-home-box--pensionet,
    .galery-home-box--raportimet,
    .galery-home-box--punesimet,
    .galery-home-box--tatimiNeProne,
    .galery-home-box--partneriteti,
    .galery-home-box--puna {

        iframe,
        video,
        img {
            height: 220px !important;
        }
    }


    .galery-row,
    .galery-row-half {
        grid-template-columns: auto !important;
        margin: 0 !important;
    }


    //side boxes ballin

    .small-side-box-section {
        justify-content: center;
    }

    //puna 

    .galery-ballin-section {
        padding: 0 !important;
    }


    // events-full-home 
    .first-news-ballin {
        a {
            .full-event-text {
                h5 {
                    margin-bottom: 5px;
                }
            }
        }
    }

    .first-news-ballin {
        padding-bottom: 5px !important;
    }


    //dokument-kerko-viti


    .file-date-docs {
        border-left: none !important;
        width: 100%;
        justify-content: flex-start;
    }

    .file-name {
        flex-direction: column !important;
    }

    .docs-grid {
        width: 100%;
    }

    .file-name a {
        word-break: break-word;
        padding: 10px 20px !important;
    }

    .file-date {
        padding-bottom: 10px;
    }

    .form-docs {
        width: 100% !important;
    }

    .search-docs-grid {
        flex-direction: column !important;
        margin-bottom: 30px;
    }

    .docs-search-btn {
        width: 100%;
    }

    //ballina lajme devider 
    .block-posts .hometitle-devider {
        margin: 0px 15px !important;
    }

    // contact 

    .contact-us__container {
        margin: 0 !important;
    }

    .contact-box-grid {
        grid-template-columns: auto;
    }


    //how do i ?

    .questions-col {
        border-right: 0px !important;
        border-bottom: 1px solid #ccc;
        padding: 20px 20px !important;
        text-align: center;
    }

    .how-do-i-box h1 {
        margin: 0 !important;
        padding: 30px 0 !important;
    }

    .how-do-i-body {
        padding: 20px 0px !important;
        margin: 0 !important;
        display: grid !important;
        overflow: scroll;
        max-height: 72vh;
        grid-template-columns: auto;
        justify-items: center;
        align-items: center;
    }

    .how-do-i-btn {
        width: 100% !important;
        padding: 1rem 0 !important;
    }

    .how-do-i-box {
        width: 100% !important;
        height: 100% !important;
        grid-area: auto;
        grid-auto-columns: auto;
        grid-template-columns: auto;
        display: grid !important;
        justify-items: center;
    }

    // tatimi ne prone


    .first-news-ballin a .block-event__date {
        width: 100% !important;
    }

    .block-tatimi-event-box {
        margin: 0 !important;
    }

    .last-news-left {
        border-right: 0 !important;
    }

    .tatimi-img img {
        width: 100% !important;
    }

    .tatimi-date-box-right {
        padding-left: 0 !important;
    }

    .full-event-button {
        font-size: 16px !important;
    }

    .tatimi-title {
        display: grid !important;
        grid-template-columns: auto auto;
        gap: 20px;

        h2 {
            font-size: 18px !important;
        }
    }

    .second-news-img img {
        width: 100% !important;
        max-height: 165px !important;
    }

    //galery box 
    .small-side-box-list {
        height: 100% !important;
    }

    .small-side-box-text {
        padding: 30px !important;
    }


    .galery-white {
        .galery-row {
            padding: 50px 25px !important;
        }
    }

    .galery-ballin-section {
        margin-top: $block-margin-top;
    }

    .galery-box {
        flex-direction: column !important;
        margin: 0 !important;
        padding-top: 0 !important;
    }

    .block-galery-home--puna,
    .block-galery-home--punesimet {
        width: 100% !important;
        max-width: 100% !important;
    }

    .galery-home-box {
        img {
            width: 100% !important;
            max-width: 100% !important;
        }

        video {
            width: 100% !important;
            max-width: 100% !important;
        }
    }

    .small-side-box-section {
        width: 100% !important;
    }

    // galery box end


    // ballinat  and ballina boxes





    .block-ballina--layout--classic {
        margin-top: $block-margin-top !important;
    }

    .box-link {
        padding: 0 !important;
        margin-bottom: $block-margin-bottom-md;
    }

    .ballinaBoxes-link {
        width: 100% !important;
    }

    .ballina-text-section {
        margin: 0 !important;
    }

    .ballinaBoxes-fp {
        margin: 0 !important;
        margin-top: $block-margin-top;
        margin-bottom: $block-margin-bottom-md;
    }

    .box-grid {
        grid-template-columns: auto !important;
    }

    .ballinaBoxes-box-img img {
        width: 100% !important;
    }

    .titlebox {
        margin-top: -8% !important;
    }

    // ballinat end


    // home boxes 

    .block-slideshow__slide-content-grid {
        position: relative !important;
        top: 0 !important;
        right: 0 !important;
    }

    .slider-boxes-grid {
        grid-template-columns: auto !important;
        padding: 50px 25px !important;
    }

    .slider-boxes-grid li img {
        position: relative !important;
        top: 0px !important;
        margin-left: 30px;
        left: 0% !important;
    }

    .slider-boxes-grid a {
        display: flex;
        width: 100%;
        padding: 25px 15px 25px !important;
        border: 1px solid $border-blue-box;
        box-shadow: $block-box-shadow-small;
        border-radius: 3px;
        background-color: #f8f9fa !important;
        margin-right: 0px !important;
        margin-bottom: 10px;
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: center;
        text-align: initial;
    }

    .slider-box-link-childs {
        margin-bottom: 10px;
        border: 1px solid #003c8726;
        box-shadow: 0px 2px 5px 0 rgb(0 0 0 / 10%);
        border-radius: 3px;
        background-color: #f8f9fa !important;
        display: flex;
        flex-direction: row !important;
        padding: 25px 15px 25px !important;
        justify-content: flex-start !important;

        a {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            margin: 0 !important;
            padding: 0 !important;
            margin-bottom: 0;
        }

        img {
            // margin-left: 45px !important;
        }
    }

    // home boxes end


    .news-title {
        margin-top: $block-margin-top;
    }

    .event-card__name {
        margin-left: 5px !important;
    }


    .date-thumbnail {
        width: 100%;
    }

    .list_date {
        width: 100% !important;
        height: 85px !important;
    }

    .event-card {
        flex-direction: column !important;
    }

    .footer-newsletter__social-links,
    .social-links__list {
        margin-top: 0px !important;
    }

    .block-events,
    .footer {
        padding: $body-container-padding-mobile !important;
    }

    .homeboxes-fp {
        padding: 0 25px;
    }

    .event-content {
        flex-direction: column !important;
    }

    .date-text {
        margin-left: 20px !important;
    }

    .full-event-text {
        margin-left: 0px !important;
    }

    .event-date-list {
        flex-direction: row !important;
        height: auto;
    }

    .event-text {
        height: auto;
        padding-left: 0px !important;
        margin-top: 10px;
    }

    .block-event__image img {
        width: 100% !important;
        object-fit: cover;
        max-height: 20vh !important;
    }

    .search__icone .search__button {
        height: 100% !important;
        background: $light-color url(../../img/Search-icone/search-input.png) right 10px center no-repeat !important;
        background-size: 26px 26px !important;
        color: $light-color !important;
        font-size: 0;
    }

    .atoz-content {
        justify-content: flex-start !important;
    }

    .footer-top-img {
        margin-right: 0px !important;
    }

    .footer-contact-list {
        padding-bottom: 15px;
    }

    .block-event-box,
    .homeboxes-fp,
    .footer-contact-list,
    .site-footer__widgets {
        margin: auto !important;
    }

    .slider-boxes {
        // background-color: #f8f9fa;
        margin-top: 30px !important;
        padding: 0px 0;
        flex-wrap: wrap;
    }

    .slider-boxes li img {
        position: relative !important;
        top: 0px !important;
        margin-left: 30px;
        left: 0% !important;
    }

    .slider-boxes a, .digitarkep {
        display: flex;
        width: 100%;
        padding: 25px 15px 25px;
        border: 1px solid #87878726;
        box-shadow: $block-box-shadow-small;
        border-radius: 3px;
        background-color: #f8f9fa !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: center;
        text-align: left;
    }

    .link-childs-list {
        display: grid;
        // gap: 10px;
    }

    // .PageSlider {
    //     display: none;
    // }

    .PageSlider {
        height: 200px !important;
    }

    .content-vegzat-content .widget-categories {
        width: 100% !important;
        margin-bottom: $block-margin-bottom-md;
    }

    .category-dekstop {
        display: none;
    }

    .category-mobile {
        display: block;
        // margin: 15px 15px !important;
    }

    .block-slideshow__body,
    .block-slideshow__slide {
        min-height: 240px;
        height: 100% !important;
    }

    .personel-body {
        justify-content: center;
    }

    .personel-item,
    .avatar-img,
    .avatar-img img {
        height: 100% !important;
        max-width: 330px !important;
    }

    .avatar-img,
    .personel-img {
        max-height: 330px !important;
    }

    .personel-card-box {
        flex-direction: column !important;
    }

    .personel-image-card {
        width: 100%;
        max-width: 100%;
        border-bottom: $personel-image-devider !important;
        border-right: none !important;

        img {
            width: 100%;
            max-height: max-content !important;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            margin: auto;
        }
    }

    .personel-card-content {
        display: inline !important;
        max-height: none !important;
        margin-top: 2vh;
    }

    .personel-info-position>.personel-info-text {
        width: 70% !important;
    }

    .personel-info-type {
        margin-top: 0vh !important;
        margin-bottom: 2vh !important;
        margin-left: 2vh !important;
    }

    .error_title {
        font-size: 35px;
    }

    .block-slideshow__slide-content {
        position: static !important;
        align-items: center !important;
        display: flex;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        justify-content: center;
    }

    .block-slideshow__slide-title {
        margin: 0 5px;
        line-height: 20px !important;
        font-size: 18px !important;
        // box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.6); //title shadow box fix mobile
        margin-left: 5px;
        margin-right: 5px;
    }

    .contact-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        width: 100%;
    }

    .video-details__box {
        iframe {
            width: 100%;
            height: 31.8vh;
        }

        video {
            width: 100%;
            height: 31.8vh;
        }
    }

    .Apliko-section {
        width: 100% !important;
    }

    .site {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .block-header__arrows-list {
        width: 67vw;
        display: flex;
        margin-top: 0px;
        justify-content: center;
    }

    .block-news {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .post-card {
        max-height: 450px !important;
    }

    .block-BlockAboutUs {
        margin-top: 100px !important;
        margin-bottom: 130px !important;
        margin-left: $margin-left-mobile !important;
        margin-right: $margin-right-mobile !important;
    }

    .block-about__image img {
        width: 100%;
        margin-bottom: $block-margin-bottom-md;
    }

    .about-section {
        flex-wrap: wrap !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .about-title {
        text-align: center;
    }

    .about-text {
        text-align: center;
    }

    .about-button {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .Apliko-section {
        width: 100% !important;
    }

    .apliko-content {
        flex-direction: column !important;
    }

    .apliko-text {
        text-align: center;
        margin-right: 0px !important;
    }

    .apliko-button {
        margin-top: 15px;
    }

    .government-fp {
        margin-top: 100px !important;
        margin-bottom: 130px !important;
    }

    .government-text {
        width: 70% !important;
    }

    .government-grid,
    .feature-box-grid {
        width: 100% !important;
    }

    .government-list {
        flex-direction: column !important;
    }

    .government-title {
        text-align: center;
    }

    .button-section-gv {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .feature-box-list {
        margin-top: 4rem;
        display: flex;
        margin-bottom: 4rem;
        flex-direction: column;
    }

    .feature-box-text p {
        text-align: left;
        /* float: left; */
    }

    .block-features__icon_box {
        padding-right: 30px !important;
    }

    .FeatureBoxes-content {
        align-items: flex-start;
    }

    .feature-box-grid {
        margin-bottom: $block-margin-bottom-md;
    }

    .feature-box-grid:last-child {
        margin-bottom: 0px !important;
    }

    .support-text {
        margin-right: 0px;
    }

    .statistics-list {
        flex-direction: column;
    }

    .statistic-grid {
        margin-bottom: 15px;
        width: 100% !important;
    }

    .statistic-text {
        width: 80% !important;
    }

    .statistic-grid:last-child {
        margin-bottom: 0px !important;
    }

    .block-features__icon {
        width: auto !important;
        margin-bottom: 0px !important;

        @include direction {
            #{$margin-inline-end}: 0vh !important;
        }
    }

    .statistics-contnet {
        padding: 16px 0vh !important;
        width: 100% !important;
        align-items: center !important;
        justify-content: center;
    }

    .contacts-text-box {
        align-items: center !important;
    }

    .text-contact h2 {
        text-align: center;
    }

    .statistic-title {
        margin-top: 1rem !important;
    }

    .support-text {
        margin-right: 0px !important;
    }

    .support-paragraph,
    .support-title {
        width: 80% !important;
    }

    .logo-footer-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer-contacts__contacts {
        display: flex !important;
        flex-wrap: nowrap !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .block-statistic--layout--classic .top-footer-grid {
        border-right: 0 !important;
    }

    .footer-contacts {
        // width: 32vh !important;
        width: 100% !important;
    }

    .footer-contact-fp {
        padding: 35px 25px !important;
    }

    .footer__bottom {
        flex-direction: column-reverse !important;
        align-items: center !important;
        align-content: center !important;
        justify-content: center !important;
        padding-top: 0% !important;
    }

    .footer-newsletter__social-links {
        margin-left: 0px !important;
    }

    .site-footer__logo img {
        width: 100%;
    }

    .totop__button {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .totop__end {
        position: relative;
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        align-content: center !important;
    }

    .totop__body {
        justify-content: center !important;
    }

    .totop__container {
        display: none !important;
    }

    .totop__start {
        display: none !important;
    }

    .block-slideshow .slick-dots {
        bottom: -30px !important;
        border: $dots-border-card;
        // box-shadow: $block-box-shadow;

    }

    // Lajmet
    .page-header__container {
        padding-bottom: 0px !important;
        margin-top: $block-margin-top;
    }


    .posts-list--layout--list .posts-list__item {
        width: 100%;
        margin: 0px 15px !important;
    }

    .post-card__image {
        width: 100% !important;

        img {
            height: 200px;
        }
    }


    .post-card__name {
        margin-top: 10px !important;
    }


    // linket navigation 

    .page-header__breadcrumb {
        padding-top: 0px !important;
    }

    // galerit 

    .galery-body {
        margin: -15px -15px !important;
    }

    .pagination {
        margin-bottom: 0px !important;
        padding: 0;
    }

    .posts-view__pagination {
        padding-top: 40px !important;
        padding-bottom: 0px !important;
    }

    .galery-img iframe,
    .galery-img video {
        height: 203px;
    }

    .post-card__info {
        padding: 10px 15px !important;
    }

}

@media (min-width: 280px) and (max-width: 360px) {

    .slider-section .box-slider .slider-boxes .slider-box-link {
        grid-gap: 20px !important;
        gap: 20px !important;
    }

    .slider-section .box-slider .slider-boxes .slider-box-link img {
        width: 50px !important;
        height: 50px !important;
    }

    .button-section-gv {
        margin-left: 2vh !important;
        margin-right: 2vh !important;
        width: auto !important;
    }

    .btn-government {
        padding-top: 15px !important;
        font-size: 12px;
    }
}

// fold screen
@media (min-width: 280px) and (max-width: 340px) {

    .slider-text {
        justify-content: start !important;
        align-items: start !important;
    }

    .titulli-slider {
        bottom: 40px !important;
        font-size: 18px !important;
        max-width: 300px !important;
    }

    .news-list {
        .post-card {
            max-height: 440px !important;
            height: 100% !important;
        }
    }

    .file-date {
        border-left: none !important;
        width: 100%;
        justify-content: flex-start;
    }

    .file-name {
        flex-direction: column !important;
    }

    .news-title h3 {
        font-size: 22px;
    }

    .how-do-i-title h1 {
        padding: 25px 30px;
        font-size: 24px;
    }

    .questions-col {
        // padding: 0px 20px !important;
        text-align: center;
        border-right: 0px !important;
    }

    .how-do-i-close {
        right: 15px !important;
        top: 0 !important;
    }

    .post-card__image {
        width: 100% !important;

        img {
            height: 130px;
        }
    }

    .event-date-right {
        padding: 0 !important;
    }

    .event-card {
        min-height: 160px !important;
    }

    .post-card {
        min-height: 415px;
        max-height: 440px !important;
    }


    .government-img img {
        width: 100%;
    }

    .footer-contact-text {
        margin-left: 15px;
    }

    .footer-contact-text p {
        text-align: left;
        margin-left: 5px;
    }

    .block-slideshow__body,
    .block-slideshow__slide {
        min-height: 140px;
        height: 100% !important;
    }


    .block-slideshow__slide-title {
        line-height: 12px !important;
        font-size: 12px !important;
    }

    .mobile-header__body,
    .mobile-header {
        height: 50px !important;
    }

    .search--location--mobile-header .search__form {
        height: 50px !important;
    }

    .app-chatbot-button {
        width: 40px;
        height: 40px;
        padding: 8px;

        img {
            width: 100%;
        }
    }

    .react-chatbot-kit-chat-container {
        width: 225px;
    }

    .react-chatbot-kit-chat-inner-container {
        height: 420px;
    }

    .react-chatbot-kit-chat-input-container {
        height: 40px;
    }

    .react-chatbot-kit-chat-message-container {
        height: 350px;
    }
}

@media (min-width: 341px) and (max-width: 767px) {
    .post-card__content p {
        height: auto !important;
    }

    .post-card__info {
        max-width: 100% !important;
    }

    .news-list {
        .post-card {
            max-height: 100% !important;
            height: 100% !important;
        }
    }

}


//fix lajmet fotot
@media (min-width: 410px) and (max-width: 500px) {


    .post-card--layout--list .post-card__image img {
        height: 240px;
        max-height: 240px !important;
    }

    .post-card--layout--list {
        height: 450px;
        max-height: 450px !important;
    }
}

@media (min-width: 501px) and (max-width: 600px) {

    .post-card_second {
        //height: 100%;
        max-height: 400px !important;
    }

    .post-card_second .post-card__image {
        max-height: 268px !important;
    }

    .slider-boxes-photo {
        grid-template-columns: 22% 22% !important;
        grid-gap: 115px !important;
        gap: 115px !important;
        justify-content: center;
    }

    .post-card {
        height: 450px;
        max-height: 448px !important;
    }

    .ballina-news-img img {
        height: 260px !important;
    }

    .post-card--layout--list .post-card__image img {
        height: 290px;
        max-height: 290px !important;
    }

    .post-card--layout--list {
        height: 480px;
        max-height: 480px !important;
    }
}

@media (min-width: 601px) and (max-width: 700px) {

    .post-card_second {
        //height: 100%;
        max-height: 400px !important;
    }

    .post-card_second .post-card__image {
        max-height: 268px !important;
    }

    .slider-boxes-photo {
        grid-template-columns: 22% 22% 22% !important;
        grid-gap: 80px !important;
        gap: 80px !important;
        justify-content: center;
    }

    .post-card {
        height: 500px !important;
        max-height: 500px !important;
    }

    .ballina-news-img img {
        height: 320px !important;
        max-width: 700px !important;
    }

    .post-card--layout--list .post-card__image img {
        height: 320px;
        max-height: 320px !important;
    }

    .post-card--layout--list {
        height: 520px;
        max-height: 520px !important;
    }
}

@media (min-width: 701px) and (max-width: 767px) {

    .post-card_second {
        //height: 100%;
        max-height: 400px !important;
    }

    .post-card_second .post-card__image {
        max-height: 268px !important;
    }

    .post-card {
        height: 500px !important;
        max-height: 500px !important;
    }

    .ballina-news-img img {
        height: 320px !important;
        max-width: 700px !important;
    }
}

@media (min-width: 701px) and (max-width: 767px) {

    .slider-boxes-photo {
        grid-template-columns: 22% 22% 22% !important;
        grid-gap: 80px !important;
        gap: 80px !important;
        justify-content: center;
    }

    .post-card {
        height: 490px !important;
        max-height: 490px !important;
    }

    .post-card--layout--list .post-card__image img {
        height: 370px;
        max-height: 370px !important;
    }

    .post-card--layout--list {
        height: 520px;
        max-height: 520px !important;
    }
}

//fix menu margin 
@media (min-width: 570px) and (max-width: 767px) {
    .mobile-header__panel {
        .container {
            margin: 0 !important;
            max-width: 100%;
        }
    }
}

@media (min-width: 341px) and (max-width: 379px){

    .slider-text {
        justify-content: start !important;
        align-items: start !important;
    }

    .titulli-slider {
        bottom: 40px !important;
        font-size: 18px !important;
        max-width: 340px !important;
    }
}

@media (min-width: 380px) and (max-width: 400px){

    .slider-text {
        justify-content: start !important;
        align-items: start !important;
    }

    .titulli-slider {
        bottom: 40px !important;
        font-size: 18px !important;
        max-width: 380px !important;
    }
}


@media (min-width: 401px) and (max-width: 767px){

    .slider-text {
        justify-content: start !important;
        align-items: start !important;
    }

    .titulli-slider {
        bottom: 40px !important;
        font-size: 20px !important;
        max-width: 385px !important;
    }
}