.skeleton-grid {

    // news skeleton
    .new-thumb-skeleton {
        width: 255px;
        height: 200px;

        span {
            height: 100%;
        }
    }

    .news-meta {
        span {
            width: 25px;
        }
    }

    .post-card__content {
        span {
            transform: scale(1, 0.5);
        }
    }

    // First news ballin
    .event-post-full {
        .thumb {
            span {
                width: 360px;
                height: 250px;
            }

        }

        .firstnews-date-skeleton {
            span {
                width: 120px;
            }
        }
    }

    .news-box {
        .lazy-load-image-background.blur {
            display: block;
            background-color: rgba(0, 0, 0, 0.11);
            width: 100%;
            height: 100% !important;
            -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
            animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
        }

        ul {
            padding: 0;
            margin: 0;
        }

        .lazy-load-image-loaded {
            animation: none !important;
            height: auto !important;
        }
    }

    // gallery
    .galery-card {
        width: 397px;
    }

    .galery-img {
        width: 100%;
        height: 250px;

        span {
            width: 100%;
            height: 100%;
        }
    }

    @media (min-width: 280px) and (max-width: 767px) {
        .lajme-list__body {
            .skeleton-grid {
                .new-thumb-skeleton {
                    width: 100vw !important;
                    height: 200px;
                }
            }
        }

        .news-box {
            .lazy-load-image-background.blur {
                display: block;
                background-color: rgba(0, 0, 0, 0.11);
                width: 100%;
                height: 200px;
                -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
            }

            .lazy-load-image-loaded {
                animation: none !important;
                height: auto !important;
            }
        }
    }
}

.skeleton-grid-events {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .event-title {
        span {
            width: 200px;
            margin-top: 25px;
        }
    }

    .event-text-skeleton {
        margin-bottom: 60px;
    }

    .event-card__name {
        span {
            margin-top: 10px;
            width: 100%;
        }
    }

    .lajme-lista-date {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .calendar-date {
        span {
            width: 40px;
        }
    }

    .calendar-date-text {
        span {
            width: 120px;
        }
    }

    .post-card__contentt {
        span { 
            width: 100%;
            transform: scale(1, 0.5);
        }
    }
}