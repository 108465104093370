@media (min-width: 280px) and (max-width: 767px) {

    .block{
        margin-bottom: 15px;
    }
    .posts-list__body {
        margin-bottom: 3px;
    }
    .personel-image-card img {
        min-height: max-content !important;
    }

    .slider-ballina--list-sm .slick-slide {
        width: 200px !important;
    }

    .slider-section .box-slider .slider-boxes .slider-box-link {
        padding-left: 0px !important;
        font-size: 18px !important;
    }

    .slider-row {
        flex-direction: column !important;
    }


    .slider-boxes {
        gap: 20px !important;

        li {
            background: none !important;
            border: none !important;
            box-shadow: none !important;
        }
    }
    
    .box-slider {
        .slider-boxes {
            gap: 0px !important;

            li {
                background: none !important;
                border: none !important;
                border-bottom: 1px solid #DDDDDD !important;
                box-shadow: none !important;

                &:last-child {
                    border-bottom: none !important;
                }

                a {
                    border: none !important;
                    box-shadow: none !important;
                }

                .digitarkep{
                    border: none !important;
                    box-shadow: none !important;
                }
            }
        }
    }

    .box-home {
        .slider-boxes {
            gap: 20px !important;
    
            li {

                &:hover {
                    transform: none !important;
                }
                a {
                    background-color: #fff !important;
                }
            }
        }
    }

}