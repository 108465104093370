.SubPage-Section {
    gap: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: $block-margin-bottom;
}

.collapse-content {
    display: none;

    .page-slider-section .slick-dots {
        display: none;
    }
}

.title-collapse {
    &:focus-visible {
        outline: none !important;
    }
}

.collapse-box {
    background-color: $contact-background;
    // height: 100%;
    height: auto;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 100%;
    // border: 1px solid $border-blue-box;
    border: 1px solid #c7c7c785;;
    // box-shadow: $block-box-shadow;
}

.menu__item--open {

    .page-slider-section .slick-dots {
        display: block;
    }

    .collapse-box {
        .collapse-content {
            margin-top: 30px;
            display: block;
            // transition-delay: 0s, 0s, 0s;
            transition: 0.2s ease-out;
            overflow: hidden;
        }
    }

    .widget-categories__expander::after {
        transform: rotateZ(90deg);
    }
}

.subpage-docs {
    margin-top: $block-margin-top;
}

.expander {
    .widget-categories__expander {
        margin-right: 0 !important;
        margin-top: 0 !important;
    }
}

@media (min-width: 280px) and (max-width: 767px) {

    .collapse-content {
        .page-slider-section .slick-dots {
            visibility: hidden !important;
        }
    }

    .menu__item--open {

        .page-slider-section .slick-dots {
            visibility: visible !important;
        }

    }
}