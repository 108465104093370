@import '../variables';

.block-docs-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 0px 10vh;
    z-index: 3;
    padding-bottom: 20px;
}