/*
// .posts-list
*/
@import '../variables';
@import '../functions';


$local-item-margin-vertical: 32px;
$local-item-margin-horizontal: 15px;


.posts-list {}
.posts-list__body {
    display: flex;
    flex-wrap: wrap;
    // flex-direction: row;
    margin: #{-$local-item-margin-vertical} #{-$local-item-margin-horizontal};
    gap: 20px;
}
.posts-list__item {
    margin: $local-item-margin-vertical $local-item-margin-horizontal;
}


.posts-list--layout--classic {
    .posts-list__item {
        width: 100%;
    }

    @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(lg-end)) {
        $local-item-margin-vertical: 24px;

        .posts-list__body {
            margin: #{-$local-item-margin-vertical} #{-$local-item-margin-horizontal};
        }
        .posts-list__item {
            margin: $local-item-margin-vertical $local-item-margin-horizontal;
        }
    }

    @media (max-width: breakpoint(sm-end)) {
        .posts-list__body {
            margin: 0;
        }
        .posts-list__item {
            margin: 0;
        }
        .posts-list__item + .posts-list__item {
            margin-top: 36px;
        }
    }

    @media (max-width: 479px) {
        .posts-list__item + .posts-list__item {
            margin-top: 30px;
        }
    }
}
.posts-list--layout--list {
    .posts-list__item {
        width: 100%;
        margin: 20px 0;
    }

    .news-list {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
}
.posts-list--layout--grid {
    .posts-list__item {
        width: calc(50% - #{$local-item-margin-horizontal*2});
    }

    @media (max-width: breakpoint(sm-end)) {
        .posts-list__body {
            margin: 0;
        }
        .posts-list__item {
            margin: 0;
            width: 100%;
        }
        .posts-list__item + .posts-list__item {
            margin-top: 36px;
        }
    }
}

.lajme-list__body {
    margin: #{-$local-item-margin-vertical} 0;
}
.lajmet-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 30px;
    justify-content: start;
}
.news-box {
    background: #fff;
    height: 100%;
    max-height: 470px;
    max-width: 255px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 7%);
}
.new-thumb {
    position: relative;
    overflow: hidden;
}
.new-thumb a, .thumb a {
    position: absolute;
    left: -150px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 44px;
    height: 44px;
    background: #fff;
    border-radius: 100%;
    z-index: 99;
    text-align: center;
    line-height: 44px;
    color: #6c713f;
    opacity: 0;
}
.new-thumb img {
    max-height: 200px!important;
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.new-thumb img {
    min-height: auto!important;
}
.new-txt {
    padding: 20px;
    text-overflow: ellipsis;
    display: -webkit-box;
    // line-clamp: 4;
    -webkit-box-orient: vertical;
}
.post-card__namee {
    font-size: 20px;
    line-height: 1.125;
    font-weight: 600;
    margin-bottom: 15px;
}
.post-card__namee a {
    color: #333;
    transition: all .05s .05s;
    font-weight: 600;
}
.news-metaa {
    margin: 0 0 5px;
    padding: 0;
    list-style: none;
    width: 100%;
    float: left;
}
.news-metaa p{
    margin-bottom: 0px !important;
}
.new-txt ul.news-metaa li {
    float: left;
    font-size: 12px;
    color: #777;
    font-weight: 400;
}
.lajme-lista-date li i {
    font-size: 16px;
    color: #ccc;
}
.lajme-lista-date li {
    display: flex;
    align-items: baseline;
    gap: 3px;
    grid-gap: 3px;
}
.new-txt p {
    color: #777;
    line-height: 18px;
    margin: 0;
    font-size: 14px;
}
.post-card__contentt {
    font-size: 15px;
    line-height: 24px;
    height: auto;
    color: #777;
}


@media (min-width: 280px) and (max-width: 767px) {
        .lajmet-grid{
            grid-template-columns: auto;
        }
        .news-box{
            max-width: 100% !important;
        }
}