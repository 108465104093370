@import '../variables';

.news-category-box {
    margin-top: $block-margin-top;
    border: 1px solid rgba(0, 0, 0, 0.025);
    box-shadow: 0px 5px 15px 0 rgb(0 0 0 / 10%);
    border-radius: 2px;

    padding: $widget-blog-padding;

    @media (max-width: 479px) {
        padding: 20px;
    }

    .mantine-Checkbox-input:checked {
        background-color: $accent-color;
        border-color: $accent-color;
    }
}

.category-mobile {
    display: none;
}

.category-select {
    width: 100% !important;
}

.search-side-item {
    border-bottom: none !important;
}

.time-content {
    display: flex;
    flex-direction: row;
}