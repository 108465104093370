/*
// .footer-contacts
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';


.footer-contacts {}
.footer-contacts__title {
    margin-bottom: 25px;
    margin-left: 50px;
}
.footer-contacts__title2 h5 {
    margin-bottom: 25px;
}
.titlee{
    margin-left: 50px;
}
.titulli{
    margin-left: 170px !important;
    padding-bottom: 15px;
}
.footer-contacts__text {
    font-size: 13px;
    line-height: 22px;
    list-style: none;
    margin: 0;
    padding: 0;

    @include direction {
        #{$padding-inline-end}: 20px;
    }

    a {
        color: inherit;
    }
}
.footer-contacts__contacts {
    font-size: 15px;
    line-height: 20px;
    list-style: none;
    padding: 0;
    margin: 16px 0px 0 5px;

    a {
        color: inherit;
    }

    li {
        position: relative;
    }
}
.footer-contacts__icon {
    display: inline-block;
    text-align: center;
    width: 22px;

    @include direction {
        #{$margin-inline-end}: 2px;
    }
}



@media (max-width: breakpoint(sm-end)) {
    .footer-contacts {
        text-align: center;
    }
    .footer-contacts__title {
        font-size: 28px;
        margin-bottom: 16px;
    }
    .footer-contacts__text {
        padding: 0;
    }
}

.footer-contact-list {
    margin: $body-container-margin;

}

.footer-contact-paragraph {
    margin-top: 1rem;
    font-weight: 600 !important;
    font-size: 20px !important;

    a {
        color: $light-opposite-color;
    }
}

.footer-contact-paragraph a:hover {
    color: $link-hover-color;
}

.footer-grid-text {
    align-items: flex-start !important;
}