.search-side-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.search-side-title {
    display: flex;
    justify-content: space-between;
    position: relative;
    // display: block;
    color: $widget-categories-color;  
    padding-top: 10px;
    padding-bottom: 10px;
    fill: $widget-categories-arrow-color;
    transition: .15s color;
    flex-grow: 1;
    cursor: pointer;

    @include direction {
        #{$padding-inline-start}: 10px;
        #{$padding-inline-end}: 10px;
    }
    &:hover {
        color: $link-hover-color;
    }

}