@import '../../variables';

.ballinaBoxes-section {
    padding: $body-new-homes-container-padding;
    // padding-top: 30px;
}

.ballinaBoxes-fp {
    margin: $body-container-margin;
    margin-top: $block-margin-top;
    margin-bottom: $block-margin-bottom;
}

.ballinaBoxes-list {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 25px;
}

.box-grid {
    display: grid;
    // grid-template-columns: auto auto auto;
    grid-template-columns: 32.1% 32.1% 32.1%;
    grid-gap: 25px;
}

.ballinaBoxes-grid {
    flex-direction: column;
    // justify-content: center;
    padding: 0;
    margin-bottom: 30px;
}

// .ballinaBoxes-box-img::after {
//     display: block;
//     content: "";
//     border-bottom: solid 4px $accent-color;
//     position: relative;
//     bottom: -52px;
//     transform: translateX(50%);
//     width: 50%;
// }

.ballinaBoxes-contnet {
    display: flex;
    align-items: flex-end;
}

.titlebox {
    width: 80%;
    max-width: 360px;
    margin-top: -4%;
    background-color: $light-color;
    border: 1px solid $border-blue-box;
    // border: 1px solid rgb(199, 199, 199);
    box-shadow: $block-box-shadow;
}

.ballinaBoxes-title {
    // width: 100%;
    // max-width: 360px;
    // margin-top: -5%;
    // background-color: $light-color;
    // padding: 20px 0;
    padding-top: 20px;
    padding-bottom: 16px;
    // border: 1px solid $border-blue-box;
    // box-shadow: $block-box-shadow;

    a {
        font-weight: $font-weight-medium;
        font-size: 18px;
        color: $accent-color;
        text-align: center;
    }
}

.titlebox::after {
    display: block;
    margin: auto;
    content: "";
    bottom: -3px;
    border-bottom: solid 4px #04408b;
    position: relative;
    width: 60%;
}

.ballinaBoxes-title,
.ballinaBoxes-link {
    a:hover {
        color: $link-hover-color;
        transition: all 0.05s 0.05s;
    }
}

.ballinaBoxes-link {
    width: 80%;
    background-color: $light-color;
    padding: 20px 0;
    border: 1px solid $border-blue-box;
    box-shadow: $block-box-shadow;

    a {
        font-weight: $font-weight-medium;
        font-size: 18px;
        color: $accent-color;
    }
}

.box-link {
    display: flex;
    grid-column: 1 / -1;
    align-items: center;
    justify-content: center;
}

.ballinaBoxes-content-text {
    line-height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.ballinaBoxes-box-img img {
    // width: 450px;
    width: 100%;
    height: 260px;
    object-fit: cover;
}


.block-ballina--layout--classic {
    margin-top: 0;
    margin-bottom: 0px;

    .block-features__divider {
        flex-shrink: 0;
        width: 2px;
        background: $card-border-color;
    }

    .block-features__item {
        align-items: center;
    }

    .block-features__subtitle {
        font-size: 12px;
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)),
    (max-width: breakpoint(sm-end)) and (min-width: breakpoint(sm-start)) {
        .block-features__item {
            padding: 12px;
        }

        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 16px;
            }
        }

        .block-features__content {
            padding-top: 0;
        }
    }

    @media (max-width: breakpoint(md-end)) and (min-width: 420px) {
        .block-features__list {
            flex-wrap: wrap;
        }

        .block-features__item {
            flex-basis: auto;
            flex-shrink: 0;
        }

        .block-features__divider:nth-child(4n) {
            width: 100%;
            height: 2px;
        }
    }

    @media (max-width: breakpoint(xs-end)) and (min-width: 420px) {
        .block-features__item {
            text-align: center;
            flex-direction: column;
        }

        .block-features__icon {
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: center;

            @include direction {
                #{$margin-inline-end}: 0;
            }
        }
    }

    @media (max-width: 419px) {
        .block-features__list {
            flex-wrap: wrap;
        }

        .block-features__item {
            flex-basis: auto;
            width: 100%;
            flex-shrink: 0;
        }

        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 24px;
            }
        }

        .block-features__divider {
            width: 100%;
            height: 2px;
        }
    }
}