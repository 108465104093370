@import '../variables';

  .options {
    padding-bottom: 15px;
  }
  
  .options-header {
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
  }
  
  .options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .option-item {
    padding: 8px 15px;
    border: 1px solid #2898ec;
    border-radius: 25px;
    color: #1f91e7;
    font-size: 0.9rem;
    margin: 3px;
    box-shadow: inset 0px 0px 0px #2273c4;
    transition: all 0.5s;
    cursor: pointer;
    text-align: center;
  }
  
  .option-item:hover {
    box-shadow: inset 195px 0px 0px #2273c4;
    color: #ffffff;
  }
  
  .stats {
    color: white;
    font-size: 0.9rem;
    background-color: rgba(45, 159, 253, 0.589);
    display: flex;
    background: linear-gradient(to top right, rgb(15, 146, 221), #055991);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }
  .column-left {
    text-align: right;

    p {
      margin-bottom: 1em;
      margin-top: 1em;
    }
  }
  
  .column-right {
    text-align: left;
    padding: 8px;

    p {
      margin-bottom: 1em;
      margin-top: 1em;
    }
  }
  
  .url-link {
    font-size: 0.9rem;
    padding: 10px;
    border-radius: 3px;
    background-color: #2273c4;
    display: flex;
    align-items: center;
    color: #fff;
  }
  .url-icon {
    width: 35px;
    margin-right: 16px;
    margin-left: 16px;
  }
  .url-header {
    font-size: $body-font-size;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 0px;
    text-transform: uppercase;
  }

  .tel-header {
    font-size:  $h4-font-size;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
  
  .app-chatbot-button {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    padding: 10px;
    color: #fff;
    background-color: $accent-color;
    border: none;
    position: fixed;
    bottom: 25px;
    z-index: 99;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.6s ease-in-out;

    img {
      width: 40px;
    }

  }
  
  .app-chatbot-button:hover {
    transform: scale(1.1);
  }

  .app-chatbot-button-icon {
    fill: #fff;
  }
  
  .react-chatbot-kit-chat-bot-message-container {
    display: flex;
    margin: 15px 0;
    justify-content: flex-start;
}

.app-chatbot-container {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  //left: 40px;
  right: 40px;
  bottom: 55px;
  z-index: 99;
  box-shadow: 5px 5px 13px rgb(91 81 81 / 40%);
  border-radius: 5px;
}

.react-chatbot-kit-chat-container {
  position: relative;
  width: 275px;
}

.react-chatbot-kit-chat-inner-container {
  height: 500px;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.2rem;
  margin-right: 12.5px;
  background-color: #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    width: 40px;
    height: 40px;
  }
}


.react-chatbot-kit-chat-bot-message {
  background-color: $accent-color !important;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #fff;
  font-weight: medium;
  position: relative;
  width: 184.5px;
  margin-left: auto;
  text-align: left;
}

.react-chatbot-kit-chat-header {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #efefef;
  font-family: Arial;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #514f4f;
  padding: 12.5px;
  font-weight: bold;
}

.react-chatbot-kit-chat-input-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}

.react-chatbot-kit-chat-input-form {
  width: 100%;
  display: flex;
}

.react-chatbot-kit-chat-message-container {
  padding: 0 17.5px 10px 17.5px;
  overflow: scroll;
  height: 424px;
}

.options {
  padding-bottom: 15px;
}

.options-header {
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.react-chatbot-kit-chat-input-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}

.react-chatbot-kit-chat-input-form {
  width: 100%;
  display: flex;
}

.react-chatbot-kit-chat-input {
  width: 100%;
  padding: 12.5px;
  border: none;
  font-size: 0.85rem;
  border-top: 1px solid #d8d8d8;
  border-bottom-left-radius: 5px;
}

.react-chatbot-kit-chat-input:focus {
  outline: none;
  z-index: 0;
  padding: 12.5px;
}

.react-chatbot-kit-chat-btn-send {
  background-color: $accent-color !important;
  width: 100px;
  display: flex;
  border: none;
  color: #fff;
  border-bottom-right-radius: 5px;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-user-chat-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-end;
}

.react-chatbot-kit-user-chat-message {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #585858;
  font-weight: medium;
  position: relative;
  text-align: left;
}

.react-chatbot-kit-user-chat-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #f1f1f1;
  position: absolute;
  right: -7px;
  top: 13px;
}

.react-chatbot-kit-user-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 12.5px;
  background-color:  $accent-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-user-avatar-icon {
  fill: $light-color;
  width: 15px;
  height: 15px;
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: $light-color;
  width: 15px;
}

.url-link {
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 3px;
  background-color: #2273c4;
  display: flex;
  align-items: center;
  color: $light-color;


  svg {
    width: 35px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.react-chatbot-kit-chat-bot-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid $accent-color !important;
  position: absolute;
  left: -7px;
  top: 13px;
}

.tel-link {
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 30px;
  background-color: #2273c4;
  display: flex;
  align-items: center;
  color: #fff;
}

