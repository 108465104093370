.page-slider-section {
    margin-bottom: $block-margin-bottom;


    .block-slideshow__body,
    .block-slideshow__slide {
        height: 471px;
    }

    .slick-dots {
        display: flex !important;
        visibility: visible;
        bottom: 20px;
    }
}

.pageslider--false {
    display: none;
}