@import '../variables';

.menu__item--open {
    .colon-childs {
        display: block !important;
        margin-left: 30px;
    }
}

.menu__item--open-sub {
    .colon-childs-sub {
        display: block !important;
        margin-left: 30px;
    }
}

.colon-childs-sub {
    display: none;
}

.menu__submenu {
    display: none;
    top: 0;
    transform: rotateY(45deg);
}

.colum-sub-content:hover {
    .menu__submenu {
        display: block;
        visibility: visible;
        opacity: 1;
        left: 100%;
        width: 100%;
        transform: rotateY(0deg);
        background: rgba(255, 255, 255, .95);
        // position: absolute;
        // left: 268px;
        // background: white;
        // width: 100%;
    }
}

.colon-childs {
    display: none !important;
}

.colum-content {
    display: flex;
    justify-content: space-between;
}

.default-nav>.nav-links__item--with-submenu>.active>.menu>.mega-colums {
    overflow: visible;
}

.default-nav>.nav-links__item--with-submenu>.active>.menu>.mega-colums>.menu__item--open .colon-childs {
    margin-left: 10px !important;
}

.default-nav>.nav-links__item--with-submenu>.active>.menu>.mega-colums>.colum-box>.colum-content>.colum-title {
    padding: 20px 15px !important;
}

.default-nav>.nav-links__item--with-submenu>.active>.menu>.mega-colums>.colum-box>.colum-content {
    padding: 0 15px;
}

.default-nav>.nav-links__item--with-submenu>.active>.menu>.mega-colums>.colum-box>.colon-childs>.menu-full>.level-3-childs>.colum-box>.colum-sub-content {
    display: flex;
}

.arrow-subchild svg {
    fill: #ccc;
}

.colum-sub-content:hover {
    .arrow-subchild svg {
        fill: $link-hover-color;
    }

    .level-2-sub {
        color: $link-hover-color;
    }

    .menu__submenu {
        .menu-full {
            .level-3-childs {
                .colum-box {
                    .colum-sub-content {
                        .level-2-sub {
                            color: $link-color;
                        }
                    }
                }
            }
        }
    }
}

.colum-sub-content:hover {
    .menu__submenu {
        .menu-full {
            .level-3-childs {
                .colum-box {
                    .colum-sub-content {
                        .level-2-sub:hover {
                            color: $link-hover-color;
                        }
                    }
                }
            }
        }
    }

}

.default-nav>.nav-links__menu>.active>.menu {
    margin: 0 !important;

    .mega-colums {
        padding: 0 !important;

        .colum-box {
            padding: 0 !important;
        }
    }
}

.megamenu_menu>.active>.menu>.mega-colums>.colum-box>.colum-content>.colum-title {
    display: flex;
    min-height: 61px;
    align-items: center;
}

.default-nav>.nav-links__menu>.active>.menu>.mega-colums>.colum-box>.colum-content>.colum-title {
    min-height: 61px;
    display: flex;
    align-items: center;
}

.colum-title {
    max-width: 400px;
    align-items: center;
    // font-weight: 600 !important;
    color: $dark-text-color;
    // text-transform: uppercase;
    font-size: 15px !important;
}

.colum-sub-content {
    color: $dark-text-color;

    span {
        color: $dark-text-color;
    }
}

.colum-sub-content span:hover {
    color: $link-hover-color !important;
}


.mega-colums {
    width: 100%;
    padding: 25px 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: auto;
    max-height: 800px;
    overflow: hidden;
    overflow: auto;
    flex-wrap: wrap;
}


.colon-childs>.row>.mega-colums {
    display: block;
}

.colum-box {
    // padding: 0 10px;

    width: 100%;

}

.colum-box:last-child {
    .colum-content a,
    .colum-sub-content a {  
        border-bottom: none !important;
    }
}

.colum-content a,
.colum-sub-content a {
    background: transparent;
    border: none;
    // width: 100%;
    font-weight: $font-weight-medium;
    font-size: 14px;
    display: block;
    padding: 10px 15px;
    border-bottom: 1px solid #ccc;
    line-height: 20px;
    width: 100%;
}

.inactive {
    display: none;
}