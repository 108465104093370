.error404 {
    height: 70vh;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.error-fp {
    height: 100vh;
    background-image: url('../../img/error/error.jpg');
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 70%);
    background-size: cover;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.error-box {
    width: 100%;
}

.error_title,
.error_text {
    color:  $not-found-title-color !important;
}

.error_title {
    font-size: 55px;
}