/*
// .site-footer
*/
@import '../variables';
@import '../functions';

.footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: $body-container-padding;
}
.footer-row {
    gap: 120px;
    justify-content: space-between;
    display: flex;
    margin: auto;
    max-width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
}
.site-footer {
    // margin-top: 30px;
    position: relative;
    z-index: 5;
    background: $footer-bg;
    color: $light-color;
    border-top: $footer-border-top;
}
.site-footer__widgets2 {
    padding: 50px 70px 50px 70px;
    
}
.site-footer__widgets3 {
    padding: 50px 70px 50px 70px;
    display: flex;
    justify-content: center;
    
}
.site-footer__widgets {
    margin: $body-container-margin;
}

.footer-links-box {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
}

.footer-links__item {
    line-height: 20px;
}

.footer_pages_order  {
    margin-top: 90px;
    margin-bottom: 30px;
}

.site-footer__bottom {
    // background-color: $light-opposite-color;
    color: $light-color;
    height: 85px;
    display: flex;
    align-items: center;

    .container {
        height: 100%;
        display: flex;
        width: 100%;
        justify-content: center;

        .footer__bottom {
            width: 100%;
            align-items: center;
        }
    }
}

.links-ft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer_pages_order{
    text-align: center;
    font-size: 18px;
    display: flex;
    // height: 30px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    span {
        border-left: 1px solid white;
        height: 100%;
    }
}

.footer_pages_color a{
    color: $light-color;
    padding-right: 30px;
    border-right: 2px solid #fff;
    &:hover {
        color: $link-hover-color;
    }
    &:last-child{
        border-right: 0px;
        padding-right: 0px;
    }
}

.footer__bottom {
    display: flex;
    flex-direction: row;
    border-top: 1px solid $footer-copyright-color;
    align-items: baseline;
    align-content: center;
    justify-content: center;
}

.site-footer__bottom2 {
    height: 54px;
    border-top: $footer-copyright-border;
    display: flex;
    align-items: center;
    justify-content: center;
}
.site-footer__copyright {
    font-size: 18px;
    color: $footer-copyright-color;

    a:hover {
        text-decoration: underline;
    }
}
.site-footer__payments {
    img {
        max-width: 100%;
    }
}
.site-footer__widget {
    & + & {
        margin-top: 24px;
    }
}
.site-footer__widget2 {
    & + & {
        margin-top: 24px;
    }
}

.logo-footer-grid {
    display: flex;
    flex-direction: column;
}

.site-footer__logo {
    margin-bottom: 12px;
    img {
        max-width: 100%;
        // width: 100%;
    }
}

.footer-links__link {
    font-size: 18px;
    line-height: 30px !important;
}

.footer-contact-text {
    display: flex;
    align-items: baseline;
    color: $footer-links-color;
    i {
        width: 35px !important;
        max-width: 22px;
        margin-right: 5px !important;
    }

    p {
        line-height: 20px;
    }
}

.footer-contact-text {
    a {
        p:hover {
            color: $link-hover-color;
        }
    }
}

@media (max-width: breakpoint(sm-end)) {
    .site-footer__widgets {
        padding-bottom: 48px;
    }
    .site-footer__widgets2 {
        padding-bottom: 48px;
    }

    .site-footer__bottom {
        // padding: 20px 0 24px;
        flex-wrap: wrap;
        height: auto;
    }

    .site-footer__copyright + .site-footer__payments {
        margin-bottom: 24px;
    }

    .site-footer__copyright,
    .site-footer__payments {
        width: 100%;
        text-align: center;
    }
}
