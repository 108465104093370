@import '../variables';

.galery-body {
    justify-content: space-between;
    margin: -15px -15px !important; 
    // margin-top: $block-margin-top !important;
    margin-bottom: $block-margin-bottom-md - 20px !important;
    gap: 0px !important;
   
}

.galery-info {
    align-items: center;
    margin: auto !important;
    padding: 10px 0;
}

.v-galery-info {
   margin: auto !important;
   padding: 10px 0;
}

.video-details__box {
    width: 100%;
}

.video-details {
    width: 100%;
}

.galery-card {
    box-shadow: 0px 5px 15px 0 rgb(0 0 0 / 10%);
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100% !important;
    margin-bottom: 0px !important;
}

.galery-item {
    margin: 15px 15px !important;
}

.galery-img img {
    height: 250px;
}

.galery-pagination {
    padding-top: 30px !important;
}

.pswp__img {
   object-fit: contain;
}

.v-galery-img {
    max-height:  $video-galery-max-height;
    iframe,video {
        width: 100%;
        height:  $video-galery-max-height;
        max-height:  $video-galery-max-height;
    }
}

.video-details__box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: $block-margin-top;
    width: 100%;
    margin-bottom: $block-margin-bottom-md;

    iframe {
        width: $video-width;
        height: $video-height;
    }

    video {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: $block-margin-top;
        width: $video-width;
        height: $video-height;
        margin-bottom: $block-margin-bottom-md;
    }
}


.galery-ballin-section {
    padding: $body-container-padding;
    background-color: #eee;
    margin-top: -10px;
}

.galery-white {
    background-color: $light-color;
    margin-bottom: 10px;

    .galery-row {
        padding: 0;
    }
}

.galery-row {
    display: grid;
    margin: $body-container-margin;
    background: $light-color;
    padding: 30px;
    gap: 30px;
    grid-template-columns: 49% 49%;
}

.galery-row-half {
    display: grid;
    margin: $body-container-margin;
    background: $light-color;
    padding: 30px;
    gap: 30px;
    grid-template-columns: 49% 49%;

    .ballinaBoxes-grid {
        width: 100%;
        max-width: 100%;
        .block-galery-home--punesimet,
        .block-galery-home--puna {
            max-width: 100%;
            .block-galery-box {
                .galery-home {
                    .galery-home-card {
                        .galery-home-box--punesimet,
                        .galery-home-box--puna {
                            video,
                            iframe,
                            img {
                                width: 100%;
                                max-width: 100%;
                                height: 380px;
                            }
                        }
                    }
                }
            }
        }
    }
    
}

.galery-row-full {
    display: grid;
    margin: $body-container-margin;
    background: $light-color;
    padding: 30px;
    gap: 30px;
    grid-template-columns: 100%;

    .block-galery-home--default,
    .block-galery-home--thesari,
    .block-galery-home--buxheti,
    .block-galery-home--tatimiNeProne,
    .block-galery-home--socialet,
    .block-galery-home--raportimet,
    .block-galery-home--partneriteti,
    .block-galery-home--pensionet,
    .block-galery-home--puna, 
    .block-galery-home--punesimet  {
        .block-galery-box {
            .galery-home {
                .galery-home-card {
                    .galery-home-box--default,
                    .galery-home-box--thesari,
                    .galery-home-box--buxheti,
                    .galery-home-box--tatimiNeProne,
                    .galery-home-box--socialet,
                    .galery-home-box--raportimet,
                    .galery-home-box--partneriteti,
                    .galery-home-box--pensionet,
                    .galery-home-box--puna,
                    .galery-home-box--punesimet {
                        video,
                        iframe,
                        img {
                            height: 580px;
                        }
                    }
                }
            }
        }
    }
}

.galery-box-content {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 0;
    flex-grow: 1;
}

.block-galery-home--puna,
.block-galery-home--punesimet{
    width: 100%;
    max-width: 450px;
}

.galery-box {
    margin: $body-container-margin;
    background: $light-color;
    padding: 30px;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
}


.block-galery-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 3;
}

.galery-home-box--puna,
.galery-home-box--punesimet {
    position: relative;
   
    video,
    iframe,
    img {
        width: 100%;
        max-width: 450px;
        height: 260px;
        object-fit: cover;
    }
}

.photo-overlay a::before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.6s;
    z-index: 1;
}

.photo-overlay a:hover::before {
    // border-color: $accent-color;
    opacity: 1;
}

.galery-home-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a {
        color: $link-color;
    }
}

.galery-home-link a:hover {
    color: $link-hover-color;
    transition:  $contnet-transition;
}
