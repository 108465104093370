/*
// .block-slideshow
*/
@import '../variables';
@import '../functions';
@import '../mixins/buttons';
@import '../mixins/direction';


$local-dots-height: 18px;
$local-dots-padding: 4px;
$local-dot-size: $local-dots-height - $local-dots-padding * 2;

.rreshtiii {
    margin-right: 0px;
    margin-left: 0px;
}

.kolonaa {
    padding-right: 0px;
    padding-left: 0px;
}

.slider-section {
    padding: 0;

    .news-ballina {
        margin-top: 0px;
        margin-bottom: 0;
    }

    .box-slider {
        margin-top: 20px;

        .slider-boxes {
            flex-direction: column;
            gap: 0px;
            margin: 0 20px;


            li {
                box-shadow: none;
                border-bottom: 1px solid #DDDDDD;

                &:last-child {
                    border-bottom: 0;
                }
            }

            .slider-box-link {
                padding: 20px 0;
                padding-left: 65px;
                display: flex;
                font-size: 28px;
                gap: 30px;
                flex-direction: row;
                font-weight: $font-weight-extra-bold;
                justify-content: flex-start;
                padding-right: 15px;

                &:hover {
                    img {
                        width: 60px;
                        height: 60px;
                    }
                }

                img {
                    position: relative;
                    top: 0;
                    width: 60px;
                    height: 60px;
                    left: 0;
                    transform: none;
                }
            }
        }
    }
}

.slider-row {
    display: flex;
    flex-direction: row;
}

.block-slideshow {
    margin-bottom: $block-margin-bottom;
    position: relative;

    .slick-slide>div>div {
        vertical-align: middle;

        &:focus {
            outline: none;
        }
    }

    .slick-arrow {
        display: flex;
        // margin-top: -340px;
        // width: 96vw;
        justify-content: space-between;
    }

    .slick-next {
        position: absolute;
        top: 50%;
        font-size: 0;
        right: 0;

    }

    .slick-prev {
        position: absolute;
        top: 50%;
        left: 0;
        font-size: 0;
    }

    .slick-dots {
        visibility: visible;
        width: auto;
        bottom: 20px;
        position: absolute;
        background: $block-slideshow-dots-bg;
        padding: $local-dots-padding;
        border-radius: $local-dots-height * .5;
        font-size: 0;
        list-style: none;
        margin: 0;

        @include direction {
            transform: translateX(-50% * $transform-direction);
            #{$inset-inline-start}: 50%;
        }

        li {
            display: inline-block;
        }

        button {
            width: 8px;
            height: 8px;
            padding: 0;
            border: none;
            border-radius: $local-dot-size * .5;
            background: $block-slideshow-dot-default-color;

            &:focus {
                outline: none;
            }

            &:hover {
                background: $block-slideshow-dot-hover-color;
            }
        }

        .slick-active button {
            background: $block-slideshow-dot-active-color;
            border: $block-slideshow-dot-active-border-color;
            width: 11px;
            height: 11px;
        }

        li+li {
            margin-left: 6px;
        }
    }
}

.slider-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titulli-slider {
    position: absolute;
    bottom: 120px;
    font-size: 28px;
    width: 100%;
    max-width: 634px;
    font-weight: 600;
    color: white;
    /* box-shadow: none; */
    text-shadow: 1px 2px 4px rgb(0 0 0 / 40%);
}


.block-slideshow__slide {
    position: relative;
    display: block;
    color: inherit;
    vertical-align: middle;


    &:hover {
        color: inherit;
    }
}

.block-slideshow__slide2 {
    position: relative;
    display: block;
    color: inherit;
    vertical-align: middle;
    margin-top: 30px;


    &:hover {
        color: inherit;
    }
}

.block-slideshow__slide-image {
    position: absolute;
    background: black;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 20%;

}

.block-slideshow__slide-image_small {
    border: 1px solid rgba(0, 0, 0, 0.025);
    // box-shadow: 0px 5px 15px 0 rgb(0 0 0 / 10%);
    border-radius: 2px;
    // background-position:center;
}

.full-page-slide {
    width: 90%;
    background-position: center;
    position: relative;
}

.fullpage-boxslide {
    display: flex;
    justify-content: center;
}

.block-slideshow__slide-image--mobile {
    display: none;
}

.slider-home {
    .block-header {
        .block-arrows {
            .block-header__arrows-list {

                .block-header__arrow--left:hover {
                    svg {
                        -webkit-filter: drop-shadow(3px 0px 2px rgba(0, 0, 0, 0.7));
                        filter: drop-shadow(3px 0px 2px rgba(0, 0, 0, 0.7));
                    }
                }

                .block-header__arrow--right:hover {
                    svg {
                        -webkit-filter: drop-shadow(-3px 0px 2px rgba(0, 0, 0, 0.7));
                        filter: drop-shadow(-3px 0px 2px rgba(0, 0, 0, 0.7));
                    }
                }

                .block-header__arrow {
                    background: transparent;
                    fill: rgba(255, 255, 255, 0.5);

                    &:hover {
                        fill: rgba(255, 255, 255, 1);

                        svg {
                            transform: scale(1.5);
                        }
                    }

                    svg {
                        transform: scale(1.4);
                    }
                }
            }
        }
    }
}

.slider-home {
    .block-slideshow__body:hover~ {
        .block-header {
            .block-arrows {
                .block-header__arrows-list {
                    .block-header__arrow {
                        background: transparent;
                        fill: rgba(255, 255, 255, 1);
                        transition: fill .5s;

                        svg {
                            transform: scale(1.5);
                            transition: transform 1s;
                        }
                    }

                    .block-header__arrow--left {
                        svg {
                            -webkit-filter: drop-shadow(3px 0px 2px rgba(0, 0, 0, 0.7));
                            filter: drop-shadow(3px 0px 2px rgba(0, 0, 0, 0.7));
                        }
                    }

                    .block-header__arrow--right {
                        svg {
                            -webkit-filter: drop-shadow(-3px 0px 2px rgba(0, 0, 0, 0.7));
                            filter: drop-shadow(-3px 0px 2px rgba(0, 0, 0, 0.7));
                        }
                    }
                }
            }
        }
    }
}


.block-slideshow__slide-title {
    color: $block-title-color;
    font-size: 18px;
    line-height: 22px;
    font-weight: $font-weight-bold;
    opacity: 0;
    transition: $contnet-transition;
}

.block-slideshow__slide-title:hover {
    color: $link-hover-color;
    transition: $contnet-transition;
}

.block-slideshow__slide-text {
    color: $block-text-color;
    line-height: 22px;
    font-size: 15px;
    opacity: 0;
    transform: translateY(15px);
    transition: all .8s .5s;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.block-slideshow__slide-button {
    margin-top: 40px;
    opacity: 0;
    transition: all 1s .4s;
}

.block-slideshow .correct-slick-active {

    .block-slideshow__slide-title,
    .block-slideshow__slide-text,
    .block-slideshow__slide-button {
        opacity: 1;
        transform: none;
    }
}

// .block-slideshow__body_small {
//     margin-top: $block-small-margin-top !important;
// }

.block-slideshow--layout--full {
    margin-top: 0px;
    margin-bottom: 0px;

    @media (min-width: breakpoint(md-start)) {
        #block-slideshow__body_small {
            height: 300px !important;
        }

        .block-slideshow__body,
        .block-slideshow__slide {
            height: 680px;
        }

        .block-slideshow__body_small {
            height: 310px !important;
        }

        #fullpage-body_slide {
            height: 320px !important;
        }

        .block-slideshow__slide-content {
            bottom: 0px;

            @include direction {
                #{$inset-inline-start}: 0px;
            }
        }

        .block-slideshow__slide-title {
            margin-bottom: 5px;
            line-height: 22px;
        }

        .block-slideshow__slide-button {
            margin-top: 48px;
        }
    }

    @media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
        .block-slideshow__slide-image--desktop {
            background-position: -70px top;
        }

        .block-slideshow__slide-content {
            @include direction {
                #{$inset-inline-start}: 56px;
            }
        }
    }

    @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
        .block-slideshow__slide-image--desktop {
            background-position: -190px top;
        }

        .block-slideshow__slide-content {
            bottom: 56px;

            @include direction {
                #{$inset-inline-start}: 48px;
            }
        }

        .block-slideshow__slide-title {
            margin-bottom: 8px;
        }

        .block-slideshow__slide-button {
            margin-top: 40px;
        }
    }
}


.block-slideshow--layout--with-departments {
    $local-margin: 15px;

    .block-slideshow__body {
        margin-top: $local-margin;
        height: 410px - $local-margin;
    }

    .block-slideshow__body_small {
        margin-top: 0px !important;
    }

    .block-slideshow__slide {
        height: 410px - $local-margin;
    }

    @media (min-width: breakpoint(lg-start)) {
        .block-slideshow__body {
            @include direction {
                #{$margin-inline-start}: (30px - $local-margin) * -1;
            }
        }
    }

    @media (max-width: breakpoint(md-end)) {
        .block-slideshow__slide-button .btn {
            @include btn-sm();
        }
    }
}


@media (max-width: breakpoint(sm-end)) {

    .block-slideshow__body,
    .block-slideshow__slide {
        height: 395px;
    }

    .block-slideshow__slide-image--mobile {
        background-position: top center;
        display: block;
    }

    .block-slideshow__slide-content {
        top: 30px;
        text-align: center;

        @include direction {
            #{$inset-inline-start}: 5%;
            #{$inset-inline-end}: 5%;
        }
    }

    .block-slideshow__slide-title {
        font-size: 26px;
        line-height: 22px;
    }

    .block-slideshow__slide-text {
        display: none;
    }

    .block-slideshow__slide-button {
        margin-top: 24px;
    }

    .block-slideshow__slide-button .btn {
        @include btn-sm();
    }
}

.PageSlider {
    object-fit: cover;
}


@media (min-width: 1690px) and (max-width: 2400px) {

    .block-slideshow__body,
    .block-slideshow__slide {
        height: 670px !important;
    }

    .slider-section {
        .box-slider {
            .slider-boxes {
                .slider-box-link {
                    font-size: 30px;

                    &:hover {
                        img {
                            width: 70px;
                            height: 70px;
                        }
                    }

                    img {
                        position: relative;
                        top: 0;
                        width: 70px;
                        height: 70px;
                        left: 0;
                        transform: none;
                    }
                }
            }
        }
    }
}