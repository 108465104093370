/*
// .nav-panel
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';


.nav-panel {
    height: $nav-panel-height;
    background: $nav-panel-bg;
    box-shadow: $nav-panel-shadow;
    color: $nav-panel-font-color;
}

.nav-panelscrolled {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    height: $nav-panel-height-scrolled;
    background-color: $nav-panel-bottom-background-scrollled;
    box-shadow: $nav-panel-shadow;
    color: $light-color;

    .nav-title {
        color: #1a253c;
    }

    .site-header__logoscroll {
        margin-bottom: 0px;
    }

    .site-header__logoscroll img {
        max-height: 50px;
    }

}

.nav-panelscrolled>.nav-panel__container>.nav-panel__row {
    justify-content: center !important;
}

.nav-panel--stuck {
    z-index: 10;
    width: 100%;
    position: fixed;
    top: 0;
    box-shadow: $nav-panel-stuck-shadow;
}

.nav-panel__container {
    display: flex;
    margin: 0 10%;
    justify-content: flex-end;
    // justify-content: center;
    height: 100%;
}

.nav-panel__row {
    display: flex;
    align-items: center;
    gap: 200px;
    position: relative;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 6%;
}

.nav-panel__logo svg {
    display: block;
    fill: $nav-panel-logo-color;
}

.nav-panel__departments {
    flex-shrink: 0;
}

.nav-panel__logo+.nav-panel__nav-links,
.nav-panel__departments+.nav-panel__nav-links {
    @include direction {
        #{$margin-inline-start}: 18px;
    }
}

.nav-panel__nav-links {
    @include direction {
        #{$margin-inline-end}: 0%;
    }
}

.nav-panel__indicators {
    display: flex;
    height: 100%;

    @include direction {
        #{$margin-inline-start}: auto;
    }
}


@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .nav-panel__nav-links {
        @include direction {
            #{$margin-inline-start}: 14px;
            #{$margin-inline-end}: 14px;
        }
    }
}

@media (min-width: 1200px) {
    .ds-lg-none {
        display: none !important;
    }

    .ds-lg-block {
        display: block !important;
    }
}

@media (min-width : 992px) and (max-width: 1200px) {
    .search__icone {
        background: #04408b26;
        display: flex;
        justify-content: center;
    }

    .search--location--mobile-header .search__button {
        width: auto !important;
        padding: 0 22px !important;
        background: transparent url(../../img/Search-icone/search.png) right 10px center no-repeat !important;
        background-size: 26px 26px !important;
        font-weight: 400;
        font-size: 0;
    }
}

@media (min-width : 1201px) and (max-width: 2400px) {
    .search__icone {
        background: $accent-color;
        display: flex;
        border-radius: 3px;
        justify-content: center;
        margin: 2px 3px;
    }

    .search--location--mobile-header .search__button {
        width: auto !important;
        padding: 0 15px !important;
        background: transparent url(../../img/Search-icone/search.png) center no-repeat !important;
        background-size: 16px 16px !important;
        font-weight: 400;
        font-size: 0;
    }
}