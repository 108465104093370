@import '../variables';
@import '../mixins/card';

.personel-block {
    width: 100% !important;
}

//personel grid
.personel-body {
    // justify-content: space-between;
    margin: 0px 0px !important;
    // margin-top: $block-margin-top !important;
    margin-bottom: $block-margin-bottom-md - 20px !important;
    flex-direction: row !important;
}

.personel-info {
    height: auto !important;
    align-items: center;
    display: flex;
    padding: 10px !important;
    margin: auto;
    flex-direction: column;
    width: 100%;
    z-index: 22;
    max-width: 587px;
    min-height: 99px !important;
    justify-content: center;

    a {
        color: $light-opposite-color !important;
    }

}

.personel-title,
.personel-position {
    text-align: center;
}

.personel-title:hover {
    p {
        color: $link-hover-color !important;
    }

    .personel-position {
        color: $light-opposite-color-text-light !important;
    }
}

.personel-position {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0px;
    letter-spacing: 1.5px;
    font-weight: $font-weight-medium !important;
    color: $light-opposite-color-text-light;
}

.personel-name {
    font-size: 24px;
    color: $light-opposite-color;
    margin-bottom: 0px !important;
}

.personel-description {
    margin-bottom: 0px !important;
}

.personel-card {
    display: flex;
    flex-direction: column;
    max-height: 100% !important;
    margin-bottom: 0px !important;
}

.personel-item {
    margin: 15px 15px !important;
    width: 100% !important;
    max-width: 250px !important;
}

.personel-img {
    height: 100%;
    max-height: 250px;
}

.avatar-img {
    width: 100% !important;
    max-width: 250px !important;
    display: flex;
    justify-content: center;
    max-height: 250px;

    img {
        width: 100%;
        max-width: 250px;
        height: 100%;
        object-position: 50% 0% !important;
    }
}

// .avatar-img img:first-child {
//     border-radius: 0% !important;
//     object-position: 50% 50% !important;
// }

.personel-pagination {
    padding-top: 30px !important;
}

//personel details data

.personel-card-box {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    background-color: $personel-background;
    border: $personel-border-card;
    box-shadow: $block-box-shadow;
    border-radius: 2px;
    margin-bottom: $block-margin-bottom-md !important;
}

.personel-list-box:hover {
    .personel-card-box {
        box-shadow: $block-box-shadow-hover;
        transition: all 0.5s;

        .personel-card-content {
            .text-section {
                .personel-card-info {
                    p {
                        color: $link-color;
                    }
                }
            }
        }
    }
}

// .personel-card-box:hover {
//     box-shadow: $block-box-shadow-hover;
// }

.personel-image-card {
    width: 100%;
    max-width: 322px;
    border-right: $personel-image-devider;

    img {
        width: 100%;
        object-fit: cover;
        max-height: 322px;
        object-position: 50% 0% !important;
    }
}

.personel-card-content {
    width: 100%;
    max-height: 322px;
    display: inline;
}

.personel-card-info {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    // margin-top: 5px;
}

.personel-info-name,
.personel-info-position,
.personel-info-born-location,
.personel-info-birthday,
.personel-info-email,
.personel-info-phone {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    letter-spacing: 0.5px;
    // font-size: 18px;
    gap: 10px;


    .personel-info-type {
        font-weight: $font-weight-medium;
        margin-top: 1vh;
        margin-bottom: 1vh;
    }

    .personel-info-text {
        font-weight: $font-weight-normal;
        margin-bottom: 1rem;
    }
}

.personel-info-position>.personel-info-text {
    width: 80% !important;
}

.personelcardImage{
    width: 100px; /* Adjust as needed */
    height: 100px; /* Ensure square shape */
    object-fit: cover; /* Keep image proportions while covering the area */
    background-color: #cccccc; /* Light gray background */
    border-radius: 8px; /* Optional: add rounded corners */
}

.personel-details-data-aboutus{
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    gap: 20px;
    // padding: 20px;

}
.arkep-keshilli{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.arkep-keshilli h6{
    color: #7e7d7d;
}
.posts-view__list-arkep{
    display: flex;
    flex-direction: column;
    gap: 45px;
    // background: #1a253c;
    color: black;
    // padding: 100px 0px;
}
.aboutus-bordi{
    margin-top: 80px;
}
.personel-details-data-aboutus a{
    color: white;
}
.personel-details-data-aboutus a:hover{
    color: #e5b838;
}
.personel-card-info-arkep{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    gap: 20px;
    padding-left: 200px;
}

.personelcardFields{
    display: flex;
    flex-direction: column;
}