@import '../variables';


.contact-body {
    padding: $body-container-padding;
}

.contact-box {
    background-color: $contact-background;
    // height: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 100%;
    // border: 1px solid $border-blue-box;
    border: 1px solid #c7c7c721;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 10%);
    // box-shadow: $block-box-shadow;
}

.contact-box-collapse {
    background-color: $contact-background;
    // height: 100%;
    height: auto;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 100%;
    // border: 1px solid $border-blue-box;
    border: 1px solid #c7c7c721;;
    // box-shadow: $block-box-shadow;
}


// .input-c-box {
//     background-color: transparent !important;
//     border: none !important;
// }

.contact-us__container {
    margin: $body-container-margin;
}


.contact-box-grid {
    display: grid;
    grid-template-columns: 47.8% 47.8%;
    grid-gap: 60px;
    margin-bottom: 30px;
}

// .contact-input-grid {
//     display: grid;
//     grid-template-rows: auto;
//     grid-template-columns: auto;
// }

.contact-text {
    display: flex;
    padding-top: 1.50vh;
    align-items: baseline;
    i {
        width: 35px !important;
        max-width: 35px;
        margin-right: 5px !important;
    }

    p {
        line-height: 20px;
    }
}

.contact-us__header {
    font-weight: $font-weight-semi-bold;
    font-size: 24px;
}


.contacts__icon {
    display: inline-block;
    text-align: center;
    width: 22px;

    @include direction {
        #{$margin-inline-end}: 2px;
    }
}