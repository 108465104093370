/*
// .block-posts
*/
@import '../variables';
@import '../functions';
@import '../mixins/post-card';

.block-posts {
    // margin-top: $block-margin-top;
    // margin-bottom: $block-margin-bottom-md;
    position: relative;
    z-index: 1;

    .news-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: $block-margin-top;
        margin-bottom: $block-margin-bottom-md;

        h3 {
            margin-bottom: 0px !important;
        }
    }

    .hometitle-devider {
        content: '';
        display: inline-block;
        width: 90%;
        margin: 0 40px;
        height: 100%;
        border-bottom: 1px solid $accent-color;
    }

    .home-title-button {
        font-weight: $font-weight-medium;
        font-size: 18px;
        min-width: 150px;

        a {
            color: $accent-color;
        }
    }

    .home-title-button a:hover {
        color: $link-hover-color;
    }

    .block-news {
        min-height: 400px !important;
        // margin-left: $margin-left-fp;
        // margin-right: $margin-right-fp;
    }

    .slick-track {
        // height: 460px;
        min-height: 400px !important;
    }

    .slick-list {
        margin: 0 -15px;
    }

    .slick-slide {
        padding: 0 15px;
    }

    .slick-slide>div>div {
        vertical-align: middle;

        &:focus {
            outline: none;
        }
    }
}

.news-home {
    display: grid;
    grid-template-columns: 48% 48%;
    // margin: 0 10vh;
    gap: 30px;
    // margin-top: 30px;
    // margin-bottom: 30px;
}

.block-posts__news {
    display: grid;
    //grid-template-columns: 50% 50%;
    grid-template-columns: 48% 48%;
    gap: 30px;
}

.block-posts__news-primary {
    display: grid;
    height: auto;
}

.primary-news {
    min-height: 600px !important;
    // margin-left: 50px;
    // margin-right: 50px !important; 
}

.news-ballina {
    margin: 0 8%;
    margin-top: 60px;
    margin-bottom: 30px;
}

.block-posts--layout--list-sm {
    @media (min-width: breakpoint(sm-start)) {
        .post-card {
            @include post-card-list();
            @include post-card-list-sm();
        }
    }

    @media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
        .post-card {
            @include post-card-list-xs();
        }
    }

    @media (max-width: breakpoint(xs-end)) {
        .post-card {
            @include post-card-grid();
        }
    }
}


.block-posts--layout--grid-nl {
    .post-card {
        @include post-card-grid();
    }
}

.block-posts--layout--grid-nl-4 {
    .post-card {
        @include post-card-grid();
    }
}

.block-posts--layout--grid-nl-5 {
    .post-card {
        @include post-card-grid();
    }

    .slick-track {
        height: 470px;
    }

    .full-page {
        margin-left: 50px;
        margin-right: 50px;
    }
}

.post-header__image img {
    width: 100%;
    max-width: 825px;
}

.news-details-topbar {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;


    .post-location {
        margin-right: 10px;
        margin-left: 10px;
    }
}

.nav-links__item-topbar {
    &:hover {
        a {
            color: $link-hover-color;
        }
    }
}