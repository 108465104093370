@import '../variables';


.block-events {
    width: 100%;
    margin: 0 auto;
    padding: $body-container-padding;
    background-color: $block-content-background;
}

.block-publications {
    margin-top: 15px;
    .posts-list__body {
    margin: 0;
    }
}

.event-header {
    .page-header__container {
        padding: 0;
    }
}

.event-box,
.event-date-box {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
}

.event-first-title {
    display: flex;
    align-items: center;
    grid-gap: 30px;
    gap: 13px;
    h2 {
        margin-bottom: 0;
        padding: 10px 0;
        font-weight:  $font-weight-semi-bold;
        font-size: 26px;
    }
    span {
        border-left: 3px solid black;
        height: 55%;
    }
    a{
        color: #000000e0;
        font-weight: 600;
        font-size: 26px;

        &:hover {
            color: $link-hover-color;
        }
    }
}


.event-title {
    font-size: 18px;
    font-weight:  $font-weight-medium;
    color: $accent-color;
}

.event-title:hover,
.event-btn:hover {
    color: $link-hover-color;
    transition: all 0.05s 0.05s;
}

.block-event-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: $body-container-margin;
    z-index: 3;
    background-color: white;
    padding: 25px 35px 35px;
    border: 1px solid #ccc;
    box-shadow: $block-box-shadow;
}

.block-event-box:hover {
    box-shadow: $block-box-shadow-hover;
}


.event-content {
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
}

.event-button {
    padding: 10px;
    font-weight:  $font-weight-medium;
    font-size: 18px;
    margin-top: 5px;
    border-top: 1px solid #ccc;

    a {
        color: $accent-color;
    }
}

.post-date-event {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    color: $light-dark-text-color !important;
}

.date-event p{
    color:  $light-opposite-color !important;
    font-size: 16px;
    margin-bottom: 0 !important;
}

.event-calendar img {
    width: 18px;
    opacity: 0.6;
}

.event-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 22px;
    
    p{
        margin-bottom: 0;
        font-style: italic;
    }
}

.event-title {
    margin-bottom: 0;
}

.block-event__image img{
    width: 122px;
    object-fit: cover;
    max-height: 61px;
}
.event-card {
    flex-direction: row;
}

.list_date {
    width: 85px ;
    min-width: 85px;
    height: 80px;
    margin: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background-color: $accent-color;

    .event-month, .event-date-day {
        text-transform: uppercase;
        color: $light-color;
        font-weight:  $font-weight-medium;
    }
}

.date-card__info {
    max-width: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    flex-wrap: nowrap !important;
    height: 100%;
    margin: 0 !important;
    padding-bottom: 10px;
}

.event-card__name {
    display: flex;
    margin-top: 5px !important;
    align-items: center;
    justify-content: flex-start;
}


.event-card {
    &:hover {
        .date-lajme {
            color: #000000;
        }
    }
}
    


.event-date-right {
    word-break: keep-all !important;
}

.event-month-list {
    margin-top: 20px !important;
    font-size: 20px !important;
}

.event-date-list {
    bottom: 12px !important;
    font-size: 35px !important;
}

.block-event__date {
    width: 46px;
    min-width: 46px;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    background-color: $accent-color;

    .event-month, .event-date-day {
        text-transform: uppercase;
        color: $light-color;
        font-weight:  $font-weight-medium;
    }
}

.block-event__date, .date-text {
    margin: 3px 0;
}

.event-month {
    margin-top: 5px;
    font-size: 14px;
}

.event-date-day {
    position: relative;
    bottom: 10px;
    font-size: 26px;
}