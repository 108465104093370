/*
// .topbar
*/
@import '../variables';
@import '../mixins/direction';


$local-item-margin: 0;

.topbar-left-box {
    .span-topbar{
        border: 1px solid white;
        height: 22px;
        gap: 10px !important;
    }
}
.topbar {
    height: $topbar-height;
    background: $topbar-bg;
    box-shadow: $topbar-shadow;
    border-bottom: $topbar-border-bottom;
    font-size: 14px;
    line-height: 14px;
    color: $topbar-font-color;
    position: relative;
    margin-left: 15px;
    z-index: 20;
}
.topbar__container {
    height: 100%;
}
.topbar__row {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 #{-$local-item-margin};
}
.topbar__item {
    margin: 0 $local-item-margin;
    height: 100%;
    display: flex;
    align-items: center;
}
.topbar__item-value {
    color: white;
    transition: all .1s;
}
.topbar__spring {
    flex-grow: 1;
}
.topbar__item--link + .topbar__item--link {
    @include direction {
        #{$margin-inline-start}: 16px;
    }
}
