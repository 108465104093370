@import '../variables';

.block-support {
    margin-top: 0px;
    margin-bottom: 0px;
}

// .Apliko-section{
//     width: 99.1%;
// }
.support-section{
    width: 99.1%;
}




.block-support {
    background-color: $accent-color;
}

.support-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    margin-bottom: 90px;
}

.support-text {
    display: flex;
    color: $light-color;
    margin-right: 30px;
    flex-direction: column;
    line-height: 30px;
    align-items: center;
}

.support-title {
    font-size: 24px;
    width: 50%;
    text-align: center;
}

.support-paragraph {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 62%;
    text-align: center;
    font-weight: $font-weight-thin;
}

.btn-support {
    background-color: $light-color;
    color: $light-opposite-color;
    // font-weight: $font-weight-normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 16px;
}