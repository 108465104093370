@import '../variables';


.block-slideshow__slide-content {
    position: relative;
    width: 100%;
    text-align: center;
    max-width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    // box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
    // right side
    // bottom: 32px;
    // bottom: 2px;
    flex-direction: row;
    margin-top: 60px;
}


.slider-boxes {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 0;
    gap: 55px;
    margin: 0 8%;

    li {
        display: flex;
        // padding: 20px 15px;
        width: 100%;
        text-align: center;
        position: relative;
        transition: all .2s ease;
        // margin-right: 1px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #000;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    }
}

.rreshtiii {
    .slider-boxes {
        li {
            text-align: start;
        }
    }
}

.slider-boxes-photo {
    display: grid;
    grid-template-columns: 22% 22% 22% 22%;
    // flex-direction: row;
    width: 100%;
    padding-left: 0;
    gap: 55px;
    margin: 0 8%;
    padding-top: 100px;
    padding-bottom: 100px;

    li {
        display: flex;
        // padding: 20px 15px;
        width: 100%;
        text-align: center;
        position: relative;
        transition: all .2s ease;
        // margin-right: 1px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #000;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        // background: #FFFFFF;
        // border: 1px solid #FFFFFF;
        // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    }
}

.slider-box-link {
    flex-direction: column;
    display: flex;
    word-break: break-word;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .8);
    padding: 150px 15px 40px;
    color: #1A253C;
    font-weight: $font-weight-semi-bold;
    font-size: 18px;
    line-height: 35px;
    width: 100%;
    height: 100%
}

.digitarkepHover:hover {
    color: #e5b838;
}

.slider-box-link-photo {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: rgba(255, 255, 255, .8);
    padding: 150px 15px 40px;
    color: #fff !important;
    font-weight: $font-weight-semi-bold;
    font-size: 22px;
    line-height: 24px;
    width: 100%;
    height: 100%;
}

.slider-box-link-childs:hover {
    cursor: default;
}

.link-childs-list {
    display: flex;
    flex-direction: column;
}

.and-devider {
    grid-gap: 5px;
    gap: 5px;
    grid-template-columns: auto auto;
    display: grid;
    flex-direction: row;
    justify-content: center;

    p {
        margin: 0;
    }

}


.slider-boxes li:hover {
    cursor: pointer;
}

.slider-boxes li {
    img {
        margin-right: 0;
        display: inline;
        transition: all .2s ease;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 64px;
        height: 64px;
    }
}

.slider-boxes-photo li:hover {
    cursor: pointer;
}

.slider-boxes-photo li {
    img {
        margin-right: 0;
        display: inline;
        transition: all .2s ease;
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 100px;
    }
}

.block-slideshow__slide-content-grid {
    position: absolute;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    top: 16vh;
    right: 6vh;
}

.slider-boxes-grid {
    display: grid;
    grid-template-columns: 200px 200px;
    grid-gap: 2px;
    padding: 0;
    margin: 0;

    li {
        display: flex;
        // padding: 20px 15px;
        width: 100%;
        text-align: center;
        position: relative;
        transition: all .2s ease;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #000;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }
}

.box-home {
    .slider-boxes li:hover {
        transform: translateY(-5px);
    }
}

.slider-box-link-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .8);
    padding: 70px 15px 12px;
    color: #000;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    height: 100%
}

.slider-box-link-grid:hover {
    img {
        width: 50px;
        height: 50px;
    }
}

.slider-boxes-grid li {
    img {
        margin-right: 0;
        display: inline;
        transition: all .2s ease;
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        width: 45px;
        height: 45px;
    }
}

.slider-boxes-grid li:hover {
    cursor: pointer;
}

.photo-menu-home {
    background: url(../../img/parallax-photo/ser-2.jpg);
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 1
}

.photo-menu-home {
    .block-slideshow__slide-content {
        background: #00000080;
    }
}

.logos-section {

    .container,
    .slider-logos,
    .slick-prevent-click,
    .slick-slider,
    .slick-initialized,
    .slick-list,
    .slick-track {
        height: 100%;
    }

    .slider-ballina--list-sm .slick-slide {
        width: auto;
    }

    .logo-box {
        height: 100%;
    }

    .logo-box a {
        outline: none;
    }
}

.slider-ballina--list-sm {

    .lazy-load-image-background.blur.lazy-load-image-loaded>img,
    .lazy-load-image-background.blur>img {
        object-fit: cover;
        min-height: 150px !important;
        width: 200px;
    }
}

.block-slideshow__body {
    .lazy-load-image-background.blur.lazy-load-image-loaded>img {
        opacity: 0.8;
    }
}

.slider-ballina--list-sm {
    .slick-slide {
        outline: none;
        width: auto;
    }
}

.slider-ballina--list-sm {
    background: #172541;
}

.e-service {
    .block-slideshow__slide-content {
        margin-top: 0px;
    }
}