/*
// ministri
*/
@import '../variables';
@import '../mixins/hacks';
@import '../mixins/direction';


.fullpage{
    background-color:rgba(244, 240, 242, 0.8);
   
}
.rreshti{
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    border-right: 1px solid #d1d5db;
    min-height: 140px;
}
.foto-ministri{
    min-height: 140px;
    position: absolute;
    top: -50px;
}
.paragrafi{
    color: #1e4e9d;
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    font-size: 1.0000rem;
    display: block;
    padding: 0px 20px;
    margin: 0px 30px 0px 210px;
    position: absolute;
    bottom: 0;
    text-decoration: underline;
}
.foto{
    display: flex;
    justify-items: center;
}
.foto-logo{
    margin-top: 30px;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
}