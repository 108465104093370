/*
// .block-banner
*/
@import '../variables';
@import '../mixins/preloader';


.block-loader {
    position: relative;
    min-height: 320px;
}
.block-loader__spinner {
    @include preloader(80px);
}


.post-loader {
    position: inherit !important;
    min-height: 80vh !important;
}

.post-spinner {
    top: calc(50% - 20vh) !important; 
    // border-top-color: $accent-color;
}
