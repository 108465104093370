@media (min-width: 992px) and (max-width: 1200px) {
    .primary-news .post-card{
        height: 417px !important;
    }
    .post-card_second .post-card__name{
        font-size: 14px !important;
    }
    .post-card_second{
        .post-card__image img{
            height: 120px !important;
        }
        max-height: 200px !important;
    }
    .primary-news {
        // .post-card {
        //     height: 480px !important;
        // }

        .post-card__image {
            max-height: 300px !important;

            image {
                height: 300px !important;
            }
        }
        // .post-card__image img{
        //     height: 100% !important;
        // }
    }
    .contact-box-grid{
        grid-template-columns: 46.4% 46.4%;
    }

    .post-card_second {
        .post-card {
            height: 280px !important;
        }

        .post-card__image {
            max-height: 105px !important;

            image {
                height: 120px !important;
            }
        }
    }

    .box-home {
        .slider-box-link {
            padding: 130px 5px 40px !important;
            // padding: 140px 15px 40px !important;
        }

        .slider-boxes li img {
            width: 60px;
            height: 60px;
            // width: 64px;
            // height: 64px;
            top: 40px !important;
        }
        .docsBallin{
            gap: 5px !important;
            .file_manager{
                min-height: 0px;
            }
            .card-docs{
                margin-bottom: 0px;
            }
        }
    }
    .docsBallin .file_manager{
        min-height: 0px !important;
    }
    .docsBallin {
        grid-gap: 30px;
        gap: 0px !important;
        margin-bottom: 30px;
    }
    .dockList{
        gap: 30px !important;
    }
    .docs-flex{
        flex-direction: column !important;
    }
    .event-first-title span {
        border-left: 2px solid black !important;
        height: 24px;
    }
    .post-card__image img{
        height: 255px !important;
    }
    .box-aboutus .slider-boxes{
        grid-template-columns: 22.2% 22.2% 22.2% 22.2% !important;
    }
    .box-aboutus{
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    .contact-us__map {
        height: 700px; 
    }

}
@media (min-width: 992px) and (max-width: 1037px) {
    .primary-news .post-card {
        height: 430px !important;
}
}
@media (min-width: 1038px) and (max-width: 1184px) {
    .primary-news .post-card {
        height: 423px !important;
}
}
@media (min-width: 1201px) and (max-width: 1399px) {

    .nav-panel__row{
        gap: 150px !important;
    }

    .post-card_second{
        .post-card__image img{
            height: 190px !important;
        }
    }
    .box-home {
        .slider-box-link {
            padding: 130px 5px 40px !important;
            // padding: 140px 15px 40px !important;
        }

        .slider-boxes li img {
            width: 60px;
            height: 60px;
            // width: 64px;
            // height: 64px;
            top: 40px !important;
        }
    }
    .docsBallin .file_manager{
        min-height: 144px !important;
    }
    .post-card__image img{
        height: 395px !important;
    }
    .contact-box-grid{
        grid-template-columns: 47% 47%;
    }
    .site-header__logo-bottom{
        margin-right: 10px !important;
    }
}

@media (min-width: 1400px) and (max-width: 1499px) {
    .nav-panel__row{
        gap: 180px !important;
    }
}

@media (min-width: 1500px) and (max-width: 1699px) {
    .slider-section .box-slider .slider-boxes {
        gap: 13px;
    }

    .faq-box-link {
        img {
            width: 120px;
        }
    }

    .primary-news {
        .post-card {
            height: 580px !important;
        }

        .post-card__image {
            max-height: 400px !important;

            image {
                height: 400px !important;
            }
        }
    }

    .box-home {
        .slider-box-link {
            padding: 140px 15px 40px !important;
        }

        .slider-boxes li img {
            top: 40px !important;
        }
    }
}

@media (min-width: 1690px) and (max-width: 1699px) {
    .slider-section .box-slider .slider-boxes {
        gap: 0px;
    }
}

@media (min-width: 1700px) and (max-width: 2000px) {
    .primary-news {
        .post-card {
            height: 545px !important;
        }

        .post-card__image {
            max-height: 400px !important;

            image {
                height: 400px !important;
            }
        }
    }
}