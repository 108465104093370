.file_manager .file a:hover .hover,
.file_manager .file .file-name small {
    display: block
}

.file_manager .file {
    padding: 0 !important
}

.file_manager .file .icon {
    text-align: center
}


.file_manager .file {
    position: relative;
    border-radius: .55rem;
    overflow: hidden
}

.file_manager .file .image,
.file_manager .file .icon {
    max-height: 180px;
    overflow: hidden;
    background-size: cover;
    background-position: top
}

.file_manager .file .hover {
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
    transition: all 0.2s ease-in-out
}

.file_manager .file a:hover .hover {
    transition: all 0.2s ease-in-out
}

.file_manager .file .icon {
    /* padding: 15px 10px; */
    color: beige;
    display: table;
    width: 10%
}

.file_manager .file .icon i {
    display: table-cell;
    font-size: 30px;
    vertical-align: middle;
    /* line-height: 100px */
}


.file_manager .file .file-name {
    padding: 10px;
    border-top: 1px solid #f7f7f7
}

.file_manager .file .file-name small .date {
    float: right
}

.folder {
    padding: 20px;
    display: block;
    color: #777
}

.card-docs {
    background: #fff;
    transition: .5s;
    border: 1px solid $footer-copyright-color;
    margin-bottom: 10px;
    border-radius: .4rem;
    position: relative;
    width: 100%;
    height: 100%;

}

.card-docs:hover {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
}


.card-docs::after {
    display: block;
    content: "";
    border-left: solid 4px transparent;
    transform: scaleY(0);
    transition: transform 250ms ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    /* width: 100%; */
    height: 100%;

}

.card-docs:hover::after {
    border-color: $accent-color;
    border-radius: .55rem;
    transform: scaleY(1);
    cursor: pointer;
}

.file-name {
    display: flex;
    max-width: 735px;
    /* line-height: 40px; */
    position: relative;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
    align-items: flex-start;
    font-size: 17px;
    color: #676767;
}

.mobile-showAll{
    display: none;
}


.dockList{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.docsBallin{
    gap: 30px;
    .file_manager{
        min-height: 131px;
    }
    .card-docs{
        margin-bottom: 0px;
    }
    .docs-flex{
        display: flex;
        flex-direction: row;
    }
}



.docs-block {
    padding: 70px 35px;
    width: 100%;
    margin: 0 auto;
}

.file-home-name {
    max-width: 100%;
}

.item-docs {
    margin: 0px !important;
}

.home-item-docs {
    height: 100%;
}

.file-name p {
    word-break: break-all;
    white-space: normal;
}

.file-date {
    /* height: 100%; */
    display: flex;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    padding-bottom: 20px;
}


.file-name a {
    color: black;
    padding: 20px 20px;
    z-index: 3;
    word-break: break-word;
    /* margin-bottom: 0; */
    padding-bottom: 0;
    font-weight: $font-weight-semi-bold;
}

.file {
    max-width: 818px !important;
}

.card:hover {
    box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, .10);
}

.file a {
    display: flex;
    flex-direction: row;
}

.item-docs {
    display: flex !important;
    flex-direction: row !important;
    align-content: center !important;
}

.posts-list__item {
    display: flex;
    flex-direction: column;
    float: left;
}

.icon-docs {
    margin-left: 35px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.docs-pagination {
    padding-top: 60px !important;
}

.form-docs {
    width: 250px;
    margin-bottom: 10px;
    height: calc(2.25rem + 2px);
    line-height: 1.5;
    padding: 0.375rem 1.25rem;
}

.search-docs-grid {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    gap: 20px;
    flex-direction: row;
    align-items: baseline;
}

.docs-search-btn:focus {
    background-color: $accent-color;
    color: $light-color;
}