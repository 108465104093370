@import './variables';

.ballina-content-text {
    padding: $body-new-homes-container-padding;
}

.ballina-text-section {
    margin: $body-container-margin;
}

.only-title-home {
    .block {
        margin-bottom: 0 !important;
        .post__header  {
            .post-header__title {
                margin-bottom: 0 !important;
            }
        }
    }
}
.hide-arkep{
    display: none;
}