@media (min-width: 767px) and (max-width: 991px) {
    .slider-ballina--list-sm .slick-slide {
        width: 200px !important;
    }

    .slider-boxes {
        li {
            background: none !important;
            border: none !important;
            box-shadow: none !important;
        }
    }

    .box-home {
        .slider-boxes {
            li {

                &:hover {
                    transform: none !important;
                }
            }
        }
    }
    .contact-us__map {
        height: 600px;
    }
    .primary-news{
        .post-card__image .lazy-load-image-background.blur.lazy-load-image-loaded>img, .post-card__image .lazy-load-image-background.blur>img{
            height: 100%;
        }
    }
    .post-card__image img{
        max-width: none;
    }
}