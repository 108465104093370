@import './variables';

// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
.full-page-boxes {
    margin-left: 50px;
    margin-right: 50px;
}

.PageSlider {
    height: 400px;
}

.boxes-fp {
    display: flex;
    justify-content: flex-start;
}

.blue-box-fix {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.sticky-button {
    display: flex;
}

// FIX TO NOT SELECT OR GRAB ICONS EITHER IMG

.block-features__icon img,
.block-features__icon_box img,
.app-chatbot-button img,
.react-chatbot-kit-chat-bot-avatar-container img,
.site-header__logoscroll img,
.site-header__logo a,
.nav-links__item>a>span,
.nav-links__item>a,
.widget-categories__row a,
.icon-docs img,
.about-button a,
.apliko-button a,
.button-section-gv a,
.support-button a,
.site-footer__logo a>img,
.site-footer__logo a {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.site__body {
    min-height: 70vh;
}


//letter spacing 

.footer-links__title,
.support-title {
    letter-spacing: $title-space;
}

// .slick-cloned {
//     display: none !important;
// }

.block-posts__slider .slick-dots .slick-active button {
    background: #04408b;
}

.block-posts__slider .slick-dots button {
    width: 25px;
    height: 10px;
    padding: 0;
    font-size: 0;
    list-style: none;
    border: none;
    // border-radius: 5px;
    background: #e0e0e0;
}

.slick-dots {
    padding: 0;
}

.how-do-i-box {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    animation-duration: 500ms !important;
    display: flex;
    z-index: 1000;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.gap-10 {
    gap: 10px;
}

.questionLink {
    align-items: center;

    svg {
        margin-bottom: 5px;
    }
}

.questionLink-childs {
    margin-left: 0 !important;
}

.how-do-i-content {
    width: 80%;
    background: white;
    height: auto;
    // background-color: #fff;
}

.how-do-i-close {
    position: relative;
    right: 40px;
    padding: 0px 0px;
    background: transparent;
    float: right;
    width: auto;
    display: flex;
    height: auto;
    font-size: 45px;
    font-weight: 100;
    // top: 10px;
    border: none;
    align-content: center;
    font-family: Lato, sans-serif;
    justify-content: center;
    align-items: baseline;
}

.how-do-i-title h1 {
    width: 100%;
    margin-left: 40px;
    text-align: center;
    padding: 15px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.how-do-i-title {
    display: flex;
}

.how-do-i-body {
    padding: 30px 0px;
    margin: 0 15px;
    border-top: 1px solid black;
    // gap: 70px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.questions-col {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 400px;
    border-right: 1px solid #ccc;
    padding: 0 20px;
    justify-content: flex-start;

    a {
        background: transparent;
        border: none;
        // width: 100%;
        font-weight: 500;
        color: $accent-color;
        font-size: 17px;
        display: block;
        padding: 5px 0px;
        line-height: 25px;
        font-family: Lato, sans-serif;
    }

    &:last-child {
        border-right: none;
        border: none;
    }
}

.questions-col a:hover {
    color: $link-hover-color;
}

.questions-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    .img-box {
        height: 90px;
        display: flex;
        width: 90px;
        background: #eee;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

.img-box img {
    width: 60px;
    height: 60px;
}

.post-header__title {
    margin-bottom: 30px !important;
}

.post__content {

    p {
        span {
            padding: 10px;
        }
    }

    a {
        color: #007bff;
    }

    a:hover {
        color: $link-hover-color;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 400;
    }

    table {
        border-collapse: separate;
        border-spacing: 0;
        border-width: 1px 0 0 1px;
        margin: 0 0 1.5em;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, .1) !important;

        tbody {
            tr {
                td {
                    border-width: 0 1px 1px 0;
                }

                td,
                th {
                    padding: 8px;
                    border: 1px solid rgba(0, 0, 0, .1);
                }
            }
        }
    }

}


.ballina--default {
    margin-top: 60px;
}


.title-collapse {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h4 {
        margin-bottom: 0;
        font-size: 22px;
    }
}

.contact-us__address_collapse {
    display: none;
}

.menu__item--open {
    .contact-box-collapse {
        .contact-us__address_collapse {
            margin-top: 30px;
            display: block;
        }
    }

    .widget-categories__expander::after {
        transform: rotateZ(90deg);
    }
}

.lajmet-grid {
    .lazy-load-image-background.blur {
        width: 100%;
        overflow: hidden;
    }
}

.lazy-load-image-background.blur {
    filter: blur(1px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
    filter: blur(0) !important;
}

.lazy-load-image-background.blur.lazy-load-image-loaded>img,
.lazy-load-image-background.blur>img {
    object-fit: cover;
    min-height: 400px;
}

.post-card__image {

    .lazy-load-image-background.blur.lazy-load-image-loaded>img,
    .lazy-load-image-background.blur>img {
        object-fit: cover;
        width: 100%;
        min-height: auto !important;
    }

    .lazy-load-image-background {
        width: 100%;
        height: 100%;
    }
}

.block-slideshow__slide-image {
    .lazy-load-image-background {
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.block-event__image {
    .lazy-load-image-background {
        width: 100%;
        height: 100%;
    }

    img {
        min-height: 61px !important;
    }
}

.ballinaBoxes-box-img {
    img {
        min-height: auto !important;
    }

    .lazy-load-image-background  {
        display: block !important;
    }
}

.titlebox {
    z-index: 2;
}

.galery-home-box--default,
.galery-home-box--thesari,
.galery-home-box--tatimiNeProne,
.galery-home-box--partneriteti,
.galery-home-box--pensionet,
.galery-home-box--puna,
.galery-home-box--punesimet,
.galery-home-box--raportimet,
.galery-home-box--socialet,
.galery-home-box--buxheti {
    img {
        min-height: auto !important;
    }
    
    .lazy-load-image-background {
        width: 100%;
        height: 100%;
    }
}

.personel-image-card {
    img {
        height: 100%;
        width: 100%;
        min-height: 300px !important;
    }
}

.post__content {
    iframe {
        width: 100% !important;
        height: 100% !important; 
    }
}

.basic-page-text {
    margin-top: 18px;
    margin-bottom: 30px;
}

.sidebar--false,
.breadcrumbs--false  {
    visibility: hidden;
    height: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
}

.mt-55 {
    margin-top: 50px;
}