@import '../variables';


.box-aboutus{
    .slider-box-link{
        background-color: #1A253C;
        color: white;
        border-radius: 8px;
        padding: 110px 15px 40px;
    }
    .slider-boxes li :hover{
        background-color: #0c89b5;
    }
    .slider-boxes li img{
        width: 45px;
        height: 45px;
        top: 30px;
    }
    .slider-boxes li a :hover{
        // background-color:#e5b838;
        color: #e5b838;
    }
    
    .slider-boxes {
        display: grid;
        grid-template-columns: 21.4% 21.4% 21.4% 21.4%;
        gap: 40px;
        margin: 0;
    }
}

.aboutus-bordi {
    .row {
        margin: 0;
    }
}

.about-page-section {
    margin-bottom: $block-margin-bottom;
}