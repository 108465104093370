@import '../variables';



.government-fp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top:  150px;
    margin-bottom: 150px;
}

.government-list {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}

.government-contnet {
    display: flex;
    align-items: flex-end;
}

.government-grid {
    flex-direction: column;
    justify-content: center;
}

.government-title {
    margin-top: 2rem;
    font-weight: $font-weight-light;
    font-size: 20px;
}

.government-numbers {
    font-size: 40px;
    font-weight: $font-weight-semi-bold;
}

.government-content-text {
    line-height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.government-text {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 50%;
    text-align: center;
    font-weight: $font-weight-thin;
}

.government-title-top h2{
    font-weight: $font-weight-normal;
    font-size: 40px;
}

.button-section-gv {
    margin-top: 60px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.btn-government {
    background-color: transparent;
    border: 1px solid $accent-color;
    color: $accent-color;
    text-transform: uppercase;
    // font-weight: $font-weight-medium;
}


.block-government--layout--classic {
    margin-top: -($block-margin-bottom - 30px);
    margin-bottom: 0px;

    .block-features__divider {
        flex-shrink: 0;
        width: 2px;
        background: $card-border-color;
    }

    .block-features__item {
        align-items: center;
    }

    .block-features__subtitle {
        font-size: 12px;
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)), (max-width: breakpoint(sm-end)) and (min-width: breakpoint(sm-start)){
        .block-features__item {
            padding: 12px;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 16px;
            }
        }
        .block-features__content {
            padding-top: 0;
        }
    }

    @media (max-width: breakpoint(md-end)) and (min-width: 420px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: calc(50% - 1px);
            flex-shrink: 0;
        }
        .block-features__divider:nth-child(4n) {
            width: 100%;
            height: 2px;
        }
    }

    @media (max-width: breakpoint(xs-end)) and (min-width: 420px) {
        .block-features__item {
            text-align: center;
            flex-direction: column;
        }
        .block-features__icon {
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: center;

            @include direction {
                #{$margin-inline-end}: 0;
            }
        }
    }

    @media (max-width: 419px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: 100%;
            flex-shrink: 0;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 24px;
            }
        }
        .block-features__divider {
            width: 100%;
            height: 2px;
        }
    }
}

