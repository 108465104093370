.mfpt-box-img  img{
    width: 300px;
    height: 200px;
    object-fit: cover;
}

.mfpt-box {
    border: 1px solid $border-blue-box;
    align-self: stretch;
    overflow: hidden;
    box-shadow: $block-box-shadow;
}

.mfpt-box:hover {
    box-shadow: $block-box-shadow-hover;
    cursor: pointer;
}

.mfpt-box:hover {
    .government-title {
        a {
            color: $link-hover-color;
        }
    }
}

.mfpt-list {
    gap: 30px;
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: row !important;
}

.homeboxes-fp {
    margin: 150px 10vh;
    padding: 0px 35px;
}