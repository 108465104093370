@import '../variables';

.footer-AtoZ {
    background-color: $light-opposite-color;
    margin: 0 !important;
}

.btn-atoz:hover {
    background-color: $accent-color !important;
    color: $light-color;
}

.btn-atoz {
    background-color: transparent !important;
    border: none !important;
    padding: 10px !important;
    height: auto;
}

.atoz-filter-btn {
    color: $accent-color;
    text-align: start;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.atoz-filter-btn:hover {
    color: $light-color;
}

.letter-filter-box {
    display: flex;
    flex-direction: row;
}

.title-atoz-section {
    border-bottom: 1px solid #ccc;
}

.atoz-filter-content {
    margin: 10px 5.5%;

    .title-atoz {
        margin-top: $block-margin-top;
        margin-bottom:  $block-margin-top;
        padding-left: $block-margin-top;
        border-left: 6px solid $accent-color;
    }
}

.title-letter h1 {
    display: flex;
    margin-right: 20px;
    width: 68px;
    height: 68px;
    color: $light-color;
    background-color: $accent-color;
    align-items: center;
    justify-content: center;
}

.filter-letter-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

.filter-letter-list {
    display: flex;
    flex-direction: column;
}

.filter-letter-devider {
    border-top: solid 1px #f6f6f6;
    padding-top: $block-margin-top;
    padding-bottom: $block-margin-top;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.letter-list {
    margin: 0;
}

.letter-row {
    width: 100%;
}

.filter-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.letter-filter-box {
    margin-top: $block-margin-top;
    padding-bottom: $block-margin-bottom-md;
    border-bottom: 1px solid #f6f6f6;
}

.atoz-text {
    font-size: 22px !important;
    color: $light-color;
    height: 100%;
}

.atoz-content {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin: 10px 5.5%;
    flex-wrap: wrap;
}