@import '../variables';


.feature-box-fp {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feature-box-list {
    margin-top: 4rem;
    display: flex;
    margin-bottom: 4rem;
}

.feature-box-contnet {
    display: flex;
}

.FeatureBoxes-content {
    display: flex;
    flex-direction: column;
    // width: 50%;
}

.block-features__icon_box {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    padding-right: 20px;
    fill: $block-features-icon-color;
    display: flex;
    justify-content: center;  
    align-items: flex-start;


    @include direction {
        #{$margin-inline-end}: 0px;
    }

    svg {
        display: block;
    }

    img {
        width: 35px;
        height: 35px;
    }
}


.feature-box-grid {
    justify-content: center;
}

.feature-box-text p{
    margin-top: 1rem;
    color: $light-opposite-color-text-light;
    font-weight: $font-weight-thin;
}

.feature-box-title {
    font-size: 24px;
    font-weight: $font-weight-normal;
}

.feature-box-content-text {
    line-height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.block-feature-box--layout--classic {
    margin-top: 0px;
    margin-bottom: 0px;

    .block-features__divider {
        flex-shrink: 0;
        width: 2px;
        background: $card-border-color;
    }

    .block-features__subtitle {
        font-size: 12px;
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)), (max-width: breakpoint(sm-end)) and (min-width: breakpoint(sm-start)){
        .block-features__item {
            padding: 12px;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 16px;
            }
        }
        .block-features__content {
            padding-top: 0;
        }
    }

    @media (max-width: breakpoint(md-end)) and (min-width: 420px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: calc(50% - 1px);
            flex-shrink: 0;
        }
        .block-features__divider:nth-child(4n) {
            width: 100%;
            height: 2px;
        }
    }

    @media (max-width: breakpoint(xs-end)) and (min-width: 420px) {
        .block-features__item {
            text-align: center;
            flex-direction: column;
        }
        .block-features__icon {
            display: flex;
            justify-content: center;

            @include direction {
                #{$margin-inline-end}: 0;
            }
        }
    }

    @media (max-width: 419px) {
        .block-features__list {
            flex-wrap: wrap;
        }
        .block-features__item {
            flex-basis: auto;
            width: 100%;
            flex-shrink: 0;
        }
        .block-features__icon {
            @include direction {
                #{$margin-inline-end}: 3vh;
            }
        }
        .block-features__divider {
            width: 100%;
            height: 2px;
        }
    }
}

.footer-contact-fp {
    background-color: $block-content-background;
    padding: 0px 35px;
}