@import '../variables';

@media (min-width: 1455px) and (max-width: 1680px) {

    .ballinaBoxes-grid,
    .ballinaBoxes-contnet,
    .ballinaBoxes-box-img {
        width: 100%;
        max-width: 450px;
    }

    .ballinaBoxes-box-img img {
        width: 100% !important;
        max-width: 450px !important;
        height: 260px !important;
    }

    .site {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }
}


//fix logo and search
@media (min-width: 1455px) and (max-width: 1662px) {
    .site-header__search {
        width: 69% !important;
    }

    .footer-row {
        display: flex;
        flex-direction: row;
        gap: 70px !important;
        flex-wrap: nowrap;
    }
}

@media (min-width: 1663px) and (max-width: 1680px) {
    .site-header__search {
        width: 25% !important;
        margin: 0 5%;
    }

    .footer-row {
        display: flex;
        flex-direction: row;
        gap: 70px !important;
        flex-wrap: nowrap;
    }
}

//fix end


@media (min-width: 1600px) and (max-width: 2400px) {
    .totop__end {
        flex-direction: row !important;
    }
}

@media (min-width: 1301px) and (max-width: 1499px) {
    .slider-section .box-slider .slider-boxes {
        gap: 12px !important;
    }
    
    .block-posts__news {
        grid-template-columns: 47% 47% !important;
    }

    .event-first-title {
        h2 {
            font-size: 27px !important;
        }

        a {
            font-size: 27px !important;
        }
    }

    .news-list {
        .post-card {
            height: 100% !important;
            max-height: 205px !important;

            .post-card__image {
                height: 100%;

                img {
                    height: 100% !important;
                    max-height: 205px !important;
                }
            }
        }
    }

    // max-height: 510px !important;
    // height: 510px !important;
    .post-card {
        height: 100% !important;
        max-height: 562px !important;
    }

    .footer-row {
        display: flex;
        flex-direction: row;
        gap: 60px !important;
        flex-wrap: nowrap;
    }

    .ballinaBoxes-grid,
    .ballinaBoxes-contnet,
    .ballinaBoxes-box-img {
        width: 100%;
    }

    .ballinaBoxes-box-img img {
        width: 100% !important;
        height: 220px !important;
    }

    .site-header__search {
        width: 65% !important;
    }
}

@media (min-width: 1681px) and (max-width: 1920px) {

    .ballinaBoxes-grid,
    .ballinaBoxes-contnet,
    .ballinaBoxes-box-img {
        width: 100%;
    }

    .ballinaBoxes-box-img img {
        width: 100% !important;
        height: 280px !important;
    }

    .block-header__arrow--right {
        margin-right: -0.3%;
    }

    .block-header__arrow--left {
        margin-right: -0.3%;
    }
}

@media (min-width: 1921px) and (max-width: 2400px) {

    .ballinaBoxes-grid,
    .ballinaBoxes-contnet,
    .ballinaBoxes-box-img {
        width: 100%;
    }

    .ballinaBoxes-box-img img {
        width: 100% !important;
        height: 320px !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .box-slider {
        .slider-boxes {
            gap: 0 !important;
        }
    }
}

@media (min-width: 1201px) and (max-width: 1300px) {
    .box-slider {
        .slider-boxes {
            gap: 0 !important;
        }

        .slider-boxes li img {
            width: 55px !important;
            height: 55px !important;
        }

        .slider-box-link:hover {
            img {
                width: 55px !important;
                height: 55px !important;
            }
        }
    }
}


@media (min-width: 992px) and (max-width: 1300px) {
    .footer-row {
        display: flex;
        flex-direction: row;
        gap: 30px !important;
        flex-wrap: nowrap;
    }

    .post-card_second {
        max-height: 400px !important;
    }

    .post-card {
        height: 598px !important;
        max-height: 604px !important;
    }

    .block-posts__news {
        grid-template-columns: 46% 46% !important;
    }

    .event-first-title h2 {
        font-size: 22px !important;
    }

    .event-first-title a {
        font-size: 22px !important;
    }

    .raportet-panel,
    .thesari-panel,
    .buxheti-panel,
    .tatimi-panel,
    .nav-panel,
    .punesimet-panel,
    .puna-panel,
    .pensionet-panel,
    .socialet-panel {
        .nav-panel__container {
            overflow: hidden;

            .nav-panel__row {
                justify-content: flex-end !important;

                .nav-links {
                    .nav-links__list {
                        .nav-links__item {
                            a {
                                font-size: 1rem;

                                .nav-title {
                                    padding: 0px 8px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // .slider-home {
    //     .block-header {
    //         .block-arrows {
    //             .block-header__arrows-list {
    //                 margin-top: -280px !important;
    //                 width: 96vw !important;
    //             }
    //         }
    //     }
    // }

    .homeboxes-fp {
        margin: 150px 2% !important;
        padding: 0px 35px;
    }

    .galery-home-box video,
    .galery-home-box img {
        width: 100% !important;
    }

    .ballinaBoxes-grid,
    .ballinaBoxes-contnet,
    .ballinaBoxes-box-img {
        width: 100%;
    }

    .ballinaBoxes-box-img img {
        width: 100% !important;
        height: 200px !important;
    }

    .site-header__search {
        width: 65% !important;
    }

    .questions-col a {
        font-size: 16px !important;
    }

    .how-do-i-body {
        flex-wrap: nowrap !important;
    }

    .img-box img {
        width: 50px !important;
        height: 50px !important;
    }

    .questions-icon .img-box {
        height: 70px !important;
        width: 70px !important;
    }

    .how-do-i-content {
        width: 90% !important;
        max-height: 90vh !important;
    }

    .block-slideshow--layout--full .block-slideshow__slide-image--desktop {
        background-position: top !important;
    }

    .content-vegzat-content .widget-categories {
        width: 100% !important;
        margin-bottom: $block-margin-bottom-md;
    }

    .about-section,
    .government-list {
        flex-wrap: wrap !important;
    }

    .about-section {
        justify-content: center !important;
        align-items: center !important;
        align-content: center !important;
    }

    .about-content {
        margin-top: $block-margin-bottom-md;
        align-items: center;
        text-align: center;
        width: 500px;
    }

    .about-box {
        width: 500px;
        max-width: 500px;
    }

    .post-card__image img {
        //height: 170px !important;
    }

    .post-card--layout--list {}

    .block-header__arrows-list {
        display: flex;
        margin-top: 0px !important;
        width: 100% !important;
        justify-content: space-between;
    }

    .site-header__logoscroll img {
        position: absolute;
        bottom: 9px;
    }

    .site-header__logoscroll {
        width: 5% !important;
    }

    html[dir=ltr] .block-slideshow--layout--full .block-slideshow__slide-content {
        left: 0px !important;
    }

    .block-slideshow--layout--full .block-slideshow__body,
    .block-slideshow--layout--full .block-slideshow__slide {
        height: 615px !important;
    }

    .post-card--layout--list {
        height: 100% !important;
        max-height: 190px !important;

        .post-card__image img {
            height: 190px !important;
        }
    }

    .avatar-img img {
        height: 100% !important;
    }

    .personel-item {
        max-height: 360px !important;
    }
}


//nav panels 

@media (min-width: 1301px) and (max-width: 1400px) {
    .slider-section .box-slider .slider-boxes .slider-box-link {
        font-size: 24px !important;
    }

    .post-card_second {
        max-height: 400px !important;
    }

    .raportet-panel,
    .thesari-panel,
    .buxheti-panel,
    .tatimi-panel,
    .nav-panel,
    .punesimet-panel,
    .puna-panel,
    .pensionet-panel,
    .socialet-panel {
        .nav-panel__container {
            .nav-panel__row {
                justify-content: flex-end !important;

                .nav-links {
                    margin-right: 0 !important;

                    .nav-links__list {
                        .nav-links__item {
                            a {
                                font-size: 17px;

                                .nav-title {
                                    padding: 0px 20px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (min-width: 909px) and (max-width: 940px) {
    .footer_pages_order {
        grid-gap: 19px !important;
        gap: 19px !important;
    }

    .footer_pages_color a {
        padding-right: 19px !important;

        &:last-child {
            padding-right: 0px !important;
        }
    }
}

// lajmet lista
@media (min-width: 992px) and (max-width: 1198px) {

    .photo-menu-home {
        .block-slideshow__slide-content {
            padding-left: 0% !important;
            padding-right: 0% !important;
        }
    }

    .slider-section .box-slider .slider-boxes .slider-box-link {
        padding: 19px 0 !important;
        padding-left: 0px !important;
        font-size: 18px !important;
    }

    .box-home,
    .box-aboutus {

        padding-left: 8% !important;
        padding-right: 8% !important;

        .slider-boxes {
            gap: 25px !important;
            margin: 0% !important;
        }

    }

    .news-list {
        .post-card {
            height: 100% !important;
            max-height: 197px !important;
        }
    }
}

@media (min-width: 1199px) and (max-width: 1300px) {

    .slider-section .box-slider .slider-boxes .slider-box-link {
        padding: 22px 0 !important;
        padding-left: 10px !important;
        font-size: 24px !important;
    }

    .news-list {
        .post-card {
            height: 100% !important;
            max-height: 205px !important;

            .post-card__image {
                height: 100%;

                img {
                    height: 100% !important;
                    max-height: 205px !important;
                }
            }
        }
    }
}

//end lajmet lista

@media (min-width: 992px) and (max-width: 1109px) {

    .slider-boxes-photo {
        margin: 0px 7% !important;
        justify-content: center;
    }

    .event-first-title {
        h2 {
            font-size: 19px !important;
        }

        a {
            font-size: 19px !important;
        }
    }

    .post-card {
        height: 100% !important;
        max-height: 615px !important;
    }
}

@media (min-width: 1110px) and (max-width: 1150px) {

    .slider-boxes-photo {
        justify-content: center;
    }

    .post-card {
        height: 100% !important;
        max-height: 615px !important;
    }
}

@media (min-width: 1151px) and (max-width: 1300px) {}

@media (min-width: 1301px) and (max-width: 1454px) {

    .block-posts__news-primary {
        height: 580px !important;
    }

    .post-card {
        height: 100% !important;
        max-height: 580px !important;
    }
}

@media (min-width: 1301px) and (max-width: 1302px) {
    .post-card {
        height: 598px !important;
        max-height: 604px !important;
    }
}

@media (min-width: 1455px) and (max-width: 1520px) {
    .post-card {
        max-height: 545px !important;
    }
}

@media (min-width: 1455px) and (max-width: 1513px) {
    .block-posts__news-primary {
        height: 580px !important;
    }

    .post-card {
        max-height: 580px !important;
    }
}

@media (min-width: 1514px) and (max-width: 1571px) {
    .post-card {
        max-height: 580px !important;
    }
}

@media (min-width: 1500px) and (max-width: 1529px) {
    .block-posts__news-primary {
        height: 580px !important;
    }
}

@media (min-width: 1572px) and (max-width: 1626px) {
    .post-card {
        max-height: 562px !important;
    }
}