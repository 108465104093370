@import '../variables';

.slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
}

.slick-dots li {
    list-style: none;
    list-style-type: none;
}

.slick-dots > li > button {
    border: none;
    padding: 9px 10px 8px;
    margin: 0 2px;
    font-weight: 500;
    border-radius: 2px;
    line-height: 1;
    height: 33px;
    background: transparent;
    color: #000000e0;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;

}

.slick-dots .slick-active {
    button {
        background: $accent-color;
        color: $light-color;
        cursor: default;
        z-index: 2;
    }
}

