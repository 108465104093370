/*
// .faq
*/
@import '../variables';
@import '../functions';


$local-transition-duration: .2s;

.faq__section {
    &+& {
        margin-top: 70px;
    }
}

.faq__section-title {
    margin-bottom: 20px;

    h3 {
        font-size: 22px;
        font-weight: $font-weight-medium;
    }
}

.faq__section-body {
    border: $card-border;
    border-radius: $card-border-radius;
    padding: 48px 44px;
}

.faq-one-question-title {
    margin-top: 40px;
    margin-bottom: -20px;
}

@media (max-width: breakpoint(md-end)) {
    .faq__section+.faq__section {
        margin-top: 40px;
    }

    .faq__section-body {
        padding: 28px 24px;
    }

    .faq__section-column+.faq__section-column {
        margin-top: 1.5em;
    }
}

.faq__section {
    .box-faq {
        display: grid;
        grid-template-columns: 18% 18% 18% 18% 18%;
        ;
        width: 100%;
        padding-left: 0;
        justify-content: space-between;
        gap: 20px;
        margin: 0;
        margin-top: 30px;

        .faq-box-link {
            background: #FFFFFF;
            // border: 1px solid rgba(0, 0, 0, 0.125);
            padding: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            gap: 30px;
            flex-direction: column;
            height: 100%;
            border-radius: $card-border-radius;
            // box-shadow: none;
            box-shadow: 0px 4px 20px rgb(0 0 0 / 15%) !important;
            border: 1px solid #FFFFFF !important;
        }
    }
}

.faq-box-link {
    img {
        width: 125px;
    }

    h6 {
        display: flex;
        margin-bottom: 0;
        align-items: center;
    }

    &:hover {
        transform: translateY(-5px);
        transition: 200ms ease-in-out;

    }
}

.faq-header {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Faq-section-title {
    margin-bottom: 0;
}

.faq-collapse {
    margin-top: 60px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 3px;

    .faq-answer-title {
        padding: 20px;
        margin: 0;
    }

    .faq-answers {
        padding: 30px;
        background: #eee;
        transition: $local-transition-duration ease-in-out;

        &:hover {
            background: #ececec;
            transition: $local-transition-duration ease-in-out;

            .answer-boxes {
                box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
                transition: $local-transition-duration ease-in-out;
            }
        }

        .answer-boxes {
            background: $light-color;
            transition: $local-transition-duration ease-in-out;
        }

        .title-collapse {
            padding: 20px;

            h4 {
                font-size: 20px;
            }
        }
    }

    .widget-categories__expander {
        margin: 0;
    }

    .collapse-box {
        transition: height 200ms;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .collapse-content {
        visibility: hidden;
        height: 0;
        opacity: 0;
        transition: height 200ms;
    }

    .menu__item--open {
        .collapse-box {
            .collapse-content {
                visibility: visible;
                height: auto;
                opacity: 1;
                transition-delay: 0s, 0s, 0s;
                transition: 200ms ease-in-out;
                overflow: hidden;

                p {
                    padding: 15px;
                    margin: 0;
                }
            }
        }

        .widget-categories__expander::after {
            transform: rotateZ(90deg);
        }
    }
}

@media (max-width: breakpoint(lg-end)) {
    .faq-header {
        gap: 20px;
  
        .btn-primary {
          width: auto;
        }
      }

    .faq {
        margin-bottom: 60px !important;
    }

    .faq__section {
        .box-faq {
            margin-top: 30px !important;
            display: grid !important;
            grid-template-columns: 31% 31% 31% !important;

            .faq-box {
                height: 100%;
            }

            .faq-box-link {
                width: 100%;
                height: 100%;
                padding: 20px !important;
            }
        }
    }

    .faq-collapse .faq-answers .title-collapse h4 {
        font-size: 20px;
    }

    .faq-collapse .faq-answer-title {
        font-size: 22px;
    }

    .faq-collapse .menu__item--open .collapse-box .collapse-content p {
        font-size: 18px;
    }
}

@media (max-width: breakpoint(md-end)) {
    .faq-header {
        gap: 20px;
  
        .btn-primary {
          width: auto;
        }
      }

    .faq {
        margin-bottom: 60px !important;
    }

    .faq__section {
        .box-faq {
            margin-top: 30px !important;
            display: grid !important;
            grid-template-columns: 31% 31% 31% !important;

            .faq-box {
                height: 100%;
            }

            .faq-box-link {
                width: 100%;
                height: 100%;
                padding: 20px !important;
            }
        }
    }

    .faq-collapse .faq-answers .title-collapse h4 {
        font-size: 18px;
    }

    .faq-collapse .faq-answer-title {
        font-size: 20px;
    }

    .faq-collapse .menu__item--open .collapse-box .collapse-content p {
        font-size: 16px;
    }
}

@media (max-width: breakpoint(sm-end)) {

    .faq-one-question-title {
        font-size: 22px !important;
    }

    .faq-header {
        gap: 20px;
  
        .btn-primary {
          width: auto;
        }
      }

    .faq__section {
        .box-faq {
            margin-top: 30px !important;
            display: grid !important;
            grid-template-columns: 47% 47% !important;

            .faq-box {
                height: 100%;
            }

            .faq-box-link {
                width: 100%;
                height: 100%;
                padding: 20px !important;
            }
        }
    }

    .faq-collapse .faq-answers .title-collapse h4 {
        font-size: 18px;
    }

    .faq-collapse .faq-answer-title {
        font-size: 20px;
    }

    .faq-collapse .menu__item--open .collapse-box .collapse-content p {
        font-size: 16px;
    }
}

@media (max-width: breakpoint(xs-end)) {
    .faq__section {
        .box-faq {
            margin-top: 30px !important;
            display: grid !important;
            grid-template-columns: 100% !important;


            .faq-box-link {
                width: 100%;
                padding: 20px !important;
            }
        }
    }

    .faq-header {
      gap: 20px;

      .btn-primary {
        width: auto;
      }
    }

    .faq-collapse .faq-answers .title-collapse h4 {
        font-size: 16px;
    }

    .faq-collapse .faq-answer-title {
        font-size: 20px;
    }

    .faq-collapse .menu__item--open .collapse-box .collapse-content p {
        font-size: 14px;
    }
}